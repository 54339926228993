import {FC, KeyboardEvent, useCallback, useRef, useState} from "react";
import classnames from "classnames";

import {useStateRef} from "../../state-ref";
import {useDropdown} from "./dropdown";
import {Input, InputComponent, useId} from ".";
import {Pill} from "../pill";
import {Span2} from "../text";

import styles from "./input.module.scss";

interface EditableSelectProps extends InputComponent<string[]> {
	placeHolder?: string;
	label: string;
}

export const EditableSelect: FC<EditableSelectProps> = ({
	id: maybeId,
	disabled,
	placeHolder,
	onChange,
	value,
	...props
}) => {
	const id = useId(maybeId);
	const [activeIndex, setActiveIndex] = useState<number>();
	const [text, setText, textRef] = useStateRef("");
	const inputReference = useRef<HTMLInputElement>(null);
	const handleUnset = useCallback(() => setActiveIndex(undefined), [setActiveIndex]);

	const handleKeyDown = useCallback(
		(e: KeyboardEvent<HTMLDivElement>) => {
			switch (e.key) {
				case "Backspace":
					if (textRef.current?.length) return;
					onChange(value.slice(0, -1));
					break;
				case "Enter":
					onChange([...value.filter(v => v !== textRef.current), textRef.current]);
					setText("");
					break;
				case "ArrowDown":
					setActiveIndex(c => ((c ?? -1) + 1) % value.length);
					break;
				case "ArrowUp":
					setActiveIndex(c => ((c ?? value.length + 1) - 1 + value.length) % value.length);
					break;
				default:
					return;
			}
			e.stopPropagation();
			e.preventDefault();
		},
		[onChange, setText, textRef, value]
	);

	const handleDelete = (val: string) => onChange(value.filter(v => val !== v));

	const popup = useCallback(
		() => (
			<Span2 className={styles.message} color="grey">
				{text.length > 0 && !value.includes(text) ? `Create "${text}"` : "No options"}
			</Span2>
		),
		[text, value]
	);

	const {open, portal, reference} = useDropdown({
		disabled,
		focusRef: inputReference,
		popup,
		portalClassName: styles.menu,
		onClose: handleUnset,
		onKeyDown: handleKeyDown,
		onMouseOut: handleUnset,
	});

	return (
		<Input baseClass="select" disabled={disabled} id={id} {...props}>
			<div
				onClick={open}
				ref={reference}
				className={styles.searchableSelect}
				tabIndex={0}
				onKeyDown={handleKeyDown}
			>
				<div className={classnames(styles.labels, styles.textDiv, styles.inputBorder)}>
					{value.map((label, index) => (
						<Pill
							tabIndex={activeIndex}
							key={index}
							textClassName={styles.pillText}
							color="black"
							text={label}
							onDelete={() => handleDelete(label)}
						/>
					))}
					<input
						ref={inputReference}
						type="text"
						className={styles.text}
						value={text}
						onChange={e => setText(e.target.value)}
						onKeyDown={handleKeyDown}
						placeholder={value.length ? "" : placeHolder}
					/>
				</div>
				{portal}
			</div>
		</Input>
	);
};
