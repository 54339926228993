import {ReactElement, forwardRef} from "react";
import classnames from "classnames/bind";
import {useLazyQuery} from "@apollo/client";
import {Mention, MentionsInput} from "react-mentions";

import {Input, useId, useValidate} from ".";
import {Text, TextProps} from "./text";
import {Span} from "../text";
import {UserAvatar} from "../user-avatar";
import {Icon} from "../images";
import {GET_MENTIONABLE_USERS, PostService, User} from "../../data";
import {useDebounceCallback} from "../../hooks/use-debounce-callback";
import {Company} from "../../data/company";

import styles from "./input.module.scss";

const bStyles = classnames.bind(styles);

interface MentionsTextProps extends TextProps {
	info?: string;
	service: PostService;
	onChange: (newValue: string, newPlainTextValue?: string) => void;
	mentionReadonly?: boolean;
}

export const MentionsText = forwardRef<HTMLTextAreaElement, MentionsTextProps>(
	(
		{
			disabled,
			id: maybeId,
			onChange,
			validate,
			value,
			service,
			className,
			bare,
			placeholder,
			mentionReadonly,
			maxLength,
			characterCount,
			onBlur,
			error,
			...props
		},
		ref
	): ReactElement => {
		const id = useId(maybeId);
		const {inputProps} = useValidate(id, value, validate);
		const info = maxLength
			? `${value?.length || 0}/${maxLength}`
			: characterCount
			? `${value?.length || 0}`
			: undefined;
		const [getMentionableUsers, {loading}] = useLazyQuery(GET_MENTIONABLE_USERS);

		const clear = () => {
			onChange("");
		};

		const getData = useDebounceCallback(async (search, callback) => {
			if (!mentionReadonly && search) {
				const mentionableResponse = await getMentionableUsers({
					variables: {
						search,
						limit: null,
						userFilter: {connected: service === "GENERAL" ? [] : [service]},
						companyFilter: {connected: service === "GENERAL" ? [] : [service]},
					},
				});
				const mentionable = [
					...(mentionableResponse?.data?.users?.items || []),
					...(mentionableResponse?.data?.companies || []),
				]
					.filter((item: User & Company) => !item.connections[service.toLowerCase()].expired)
					.map((item: User & Company) => {
						const connection = item.connections[service.toLowerCase()] ?? {};
						const id = connection[service === "LINKEDIN" ? "urn" : "identifier"];
						return {
							...item,
							id: service === "TWITTER" ? `@${id}` : id,
							internalId: item.id,
							display: connection.displayName || item.name || `${item.firstName} ${item.lastName}`,
						};
					})
					.sort((a, b) => a.display.localeCompare(b.display));
				callback(mentionable);
			}
		}, 200);

		return (
			<Input
				baseClass="text"
				className={bStyles("textDiv", "mentionsText", {inputBorder: !bare}, className)}
				disabled={disabled}
				id={id}
				info={!["GENERAL", "FACEBOOK", "INSTAGRAM"].includes(service) ? info : undefined}
				{...props}
				{...inputProps}
				error={inputProps.error || error}
			>
				{!["GENERAL", "FACEBOOK", "INSTAGRAM"].includes(service) ? (
					<MentionsInput
						inputRef={ref}
						placeholder={placeholder}
						value={value || ""}
						disabled={disabled}
						className={"mentions"}
						classNames={styles}
						onBlur={onBlur}
						style={{
							control: {
								minHeight: 115,
							},
							"&multiLine": {
								input: {
									padding: "7px 15px",
									margin: 0,
									borderColor: "transparent",
								},
								highlighter: {
									padding: "7px 15px",
								},
							},
						}}
						onChange={(e, newValue, newPlainTextValue) => onChange(newValue, newPlainTextValue)}
						customSuggestionsContainer={children => (
							<div className={styles.mentionsInputSuggestions}>{children}</div>
						)}
					>
						<Mention
							trigger="@"
							isLoading={loading}
							data={getData}
							appendSpaceOnAdd={true}
							renderSuggestion={(entry, _, __, ___, focused) => {
								const user = entry as User & Company & {internalId: number; display: string};

								return (
									<div className={bStyles("mention-user", {focused})}>
										{user.name ? (
											<Span className={styles.account}>
												<Icon icon="account" />
												{user.display || user.name}
											</Span>
										) : (
											<UserAvatar userId={user.internalId} size="extraSmall" name />
										)}
									</div>
								);
							}}
						/>
					</MentionsInput>
				) : (
					<Text
						{...props}
						disabled={disabled}
						bare
						value={value}
						onChange={onChange}
						placeholder={placeholder}
						info={`${value?.length || 0}/${maxLength}`}
						onBlur={onBlur}
						ref={ref}
					/>
				)}
				{!disabled && value && <Icon icon="close" onClick={!loading && clear} className={styles.clear} />}
			</Input>
		);
	}
);

MentionsText.displayName = "MentionsText";
