import {ReactElement} from "react";
import classnames from "classnames/bind";
import {Outlet} from "react-router-dom";

import {P, P1, Span1} from "../../../components/text";
import {Card} from "../../../components/card";
import {Button, Separator} from "../../../components/input";
import {useMyUser} from "../../../data";
import {SSOModal} from "./sso";
import {TeamsCard} from "./teams";
import {SalesforceCard} from "./salesforce";
import {HubspotCard} from "./hubspot";
import {useNewModal as useModal} from "../../../modals";
import {SlackCard} from "./slack";
import {TremendousCard} from "./tremendous";

import styles from "./integrations.module.scss";

const bStyles = classnames.bind(styles);

export const Integrations = (): ReactElement => {
	const {open: ssoOpen, modal: ssoModal} = useModal({maxWidth: "800px"});
	const {org, role} = useMyUser();

	return (
		<div className={styles.integrations}>
			<h3>Integrations</h3>
			<Span1 className={styles.description}>
				Simplify communication, manage notifications, and unify your platforms with these key integrations.
			</Span1>
			<Separator horizontal />
			{role === "admin" && (
				<>
					<P1 bold>Company</P1>
					<div className={bStyles("integrationsRow")}>
						<TeamsCard />
						<SlackCard />
						<SalesforceCard />
						<HubspotCard />
						{org.features.sso && (
							<Card space={false} className={bStyles("integrationsCard")}>
								<h5 className="space">Single Sign-On</h5>
								<P color="grey">
									Single sign-on (SSO) is an authentication process where users can log in to multiple
									applications from different devices using a single set of credentials.{" "}
									<a
										href="https://cvssupport.wpenginepowered.com/article/single-sign-on-with-clearview-social"
										target="_blank"
										rel="noreferrer"
									>
										Learn More
									</a>{" "}
									about SSO.
								</P>
								<div className={styles.bottom}>
									<Button invert onClick={ssoOpen} icon="settings" value="Configure" />
								</div>
							</Card>
						)}
					</div>
					<P1 bold className={styles.headingPersonal}>
						Personal
					</P1>
				</>
			)}
			<div className={bStyles("integrationsRow")}>
				<TremendousCard />
				<Card space={false} className={bStyles("integrationsCard")}>
					<h5 className="space">Share via Bookmark</h5>
					<P color="grey">
						Share or add articles while you’re browsing the web by adding the Clearview Social Sharing
						Bookmark to your browser.
					</P>
					<P>
						<a
							href="https://cvssupport.wpenginepowered.com/article/using-the-clearview-social-bookmarks"
							target="_blank"
							rel="noreferrer"
						>
							Learn More
						</a>
					</P>
				</Card>
			</div>
			<SSOModal modal={ssoModal} />
			<Outlet />
		</div>
	);
};
