import {ReactElement, createContext, useCallback, useContext, useState} from "react";
import classnames from "classnames";
import {v4 as uuidv4} from "uuid";
import {
	ApolloCache,
	DefaultContext,
	MutationHookOptions,
	MutationTuple,
	OperationVariables,
	useMutation,
} from "@apollo/client";
import {DocumentNode} from "graphql";
import {TypedDocumentNode} from "@graphql-typed-document-node/core";
import {captureException} from "@sentry/react";

import {Icon, IconType} from "../components/images";
import {Timeout} from "../types";
import {getCacheUpdate} from "../cache";

import styles from "./toast.module.scss";

/*
	Timeout is in seconds for the toast to auto-hide.
	Null means never auto-hide.
	An absent parameter means the default:
	5 seconds for green, and never for blue and red.
*/
interface ToastArgs {
	color: "blue" | "red" | "green";
	text: string;
	icon?: IconType | null | false;
	timeout?: number | null;
}

interface ToastData extends Omit<ToastArgs, "timeout"> {
	id: string;
	timer: Timeout | undefined;
	fading: boolean;
	cancelled: boolean;
}

type ToastHook = (toast: ToastArgs) => void;

const ToastContext = createContext<ToastHook>(() => undefined);

export const ToastProvider = ({children}: {children: ReactElement}): ReactElement => {
	const [toasts, setToasts] = useState<ToastData[]>([]);

	const renderToast = ({icon, color, text, id, fading}: ToastData) => {
		if (icon === undefined)
			icon = color === "green" ? "circle-check" : color === "red" ? "warning" : "information";
		return (
			<div
				className={classnames(styles.toast, styles[color], fading && styles.hidden)}
				onClick={() => cancel(id)}
				onMouseEnter={() => stopFade(id)}
				onMouseOut={() => startFade(id)}
				key={id}
			>
				<div className={styles.text}>
					{icon && <Icon icon={icon} />}
					<h5>{text}</h5>
				</div>
				<Icon icon="close" className={styles.close} onClick={() => remove(id)} />
			</div>
		);
	};

	const fade = useCallback((id: string) => {
		const timer = setTimeout(() => remove(id), 1000);
		setToasts(c => c.map(t => (t.id !== id ? t : {...t, fading: true, timer})));
	}, []);

	const toast = useCallback(
		({timeout, ...toast}: ToastArgs) => {
			const id = uuidv4();
			let timer: Timeout | undefined;
			if (timeout === undefined) timeout = toast.color === "green" ? 5 : null;
			if (timeout !== null) timer = setTimeout(() => fade(id), timeout * 1000);
			setToasts(c => [...c, {...toast, id, timer, fading: false, cancelled: timeout === null}]);
		},
		[fade]
	);

	const cancel = (id: string) => {
		const timer = toasts.find(t => t.id === id)?.timer;
		if (timer) clearTimeout(timer);
		setToasts(c => c.map(t => (t.id !== id ? t : {...t, fading: false, timer: undefined, cancelled: true})));
	};

	const stopFade = (id: string) => {
		const timer = toasts.find(t => t.id === id)?.timer;
		if (timer) clearTimeout(timer);
		setToasts(c => c.map(t => (t.id !== id ? t : {...t, fading: false, timer: undefined})));
	};

	const startFade = (id: string) => {
		const toast = toasts.find(t => t.id === id);
		if (!toast || toast.cancelled) return;
		if (toast.timer) clearTimeout(toast.timer);
		const timer = setTimeout(() => fade(id), 5000);
		setToasts(c => c.map(t => (t.id !== id ? t : {...t, timer})));
	};

	const remove = (id: string) => setToasts(c => c.filter(t => t.id !== id));

	return (
		<ToastContext.Provider value={toast}>
			{toasts.length > 0 && <div className={styles.container}>{toasts.map(renderToast)}</div>}
			{children}
		</ToastContext.Provider>
	);
};

export const useToast = (): ToastHook => useContext(ToastContext);

export function useMutationToast<
	/* eslint-disable @typescript-eslint/no-explicit-any */
	TData = any,
	TVariables = OperationVariables,
	TContext = DefaultContext,
	TCache extends ApolloCache<any> = ApolloCache<any>
	/* eslint-enable @typescript-eslint/no-explicit-any */
>(
	mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
	options: MutationHookOptions<TData, TVariables, TContext, TCache> = {}
): MutationTuple<TData, TVariables, TContext, TCache> {
	const toast = useContext(ToastContext);
	const update = getCacheUpdate(mutation);

	return useMutation(mutation, {
		...options,
		...update,
		onError: error => {
			toast({color: "red", text: error.message});
			if (options.onError) {
				options.onError(error);
			} else {
				captureException(error);
			}
		},
	});
}
