import {ReactElement, Ref, forwardRef, ReactNode} from "react";
import classnames from "classnames/bind";

import {Arrow, Icon, IconType} from "../images";
import {Input, noBubble, useId} from ".";
import {ColorWithGrey, EmptyRefComponent} from "../../types";

import styles from "./input.module.scss";
const bStyles = classnames.bind(styles);

const iconHeight = {
	small: 16,
	medium: 20,
	large: 24,
};

export interface ButtonProps extends EmptyRefComponent<HTMLButtonElement> {
	id?: string;
	onClick: () => void;
	value?: string;
	color?: ColorWithGrey;
	invert?: boolean;
	border?: boolean;
	disabled?: boolean;
	loading?: boolean;
	active?: boolean;
	icon?: IconType;
	IconComponent?: ReactNode;
	iconSize?: "small" | "medium" | "large";
	large?: boolean;
	autoFocus?: boolean;
	arrow?: "up" | "down" | "left" | "right";
}

const baseButton = (
	{
		id,
		baseClass,
		onClick,
		value,
		color = "blue",
		invert,
		border = true,
		active,
		disabled,
		loading,
		icon,
		IconComponent,
		iconSize = baseClass === "smallButton" ? "small" : "large",
		large = false,
		arrow,
		autoFocus,
		...props
	}: ButtonProps & {id: string; baseClass: string},
	ref: Ref<HTMLButtonElement>
): ReactElement => (
	<Input disabled={disabled || loading} id={id} {...props}>
		<button
			ref={ref}
			id={id}
			onClick={noBubble(onClick)}
			disabled={disabled || loading}
			className={bStyles({
				[baseClass]: true,
				border,
				[color]: true,
				active,
				disabled,
				invert,
				large,
				loading,
				noValue: !value,
				overlay: true,
				customIcon: !!IconComponent,
			})}
			autoFocus={autoFocus ?? false}
		>
			{icon && <Icon icon={icon} height={iconHeight[iconSize]} />}
			{IconComponent}
			{value}
			{arrow && <Arrow direction={arrow} />}
			{loading && (
				<div className={styles.loadingDiv}>
					<img src="/loading.png" />
				</div>
			)}
		</button>
	</Input>
);

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({id: maybeId, ...props}: ButtonProps, ref: Ref<HTMLButtonElement>): ReactElement => {
		const id = useId(maybeId);
		return baseButton({id, baseClass: "button", ...props}, ref);
	}
);

export const SmallButton = forwardRef<HTMLButtonElement, ButtonProps>(
	({id: maybeId, ...props}: ButtonProps, ref: Ref<HTMLButtonElement>): ReactElement => {
		const id = useId(maybeId);
		return baseButton({id, baseClass: "smallButton", ...props}, ref);
	}
);

Button.displayName = "Button";
SmallButton.displayName = "SmallButton";
