import {FC, useMemo} from "react";
import {useQuery} from "@apollo/client";

import {Span3} from "../../../components/text";
import {Checkbox, InputRow, Select, Text} from "../../../components/input";
import {useDirtyCopy} from "../../../dirty-copy";
import {EditableSelect} from "../../../components/input/editable-select";
import {useMutationToast} from "../../../toast";
import {useMyUser} from "../../../data/user";
import config from "../../../config";
import {SAML_SETTINGS, UPDATE_ORG, UPDATE_SAML_SETTINGS} from "../../../data/org";
import {deleteTypename} from "../../../data";
import {ConfirmModal, ModalData} from "../../../modals/new";

import styles from "./integrations.module.scss";

export const SSOModal: FC<{modal: ModalData}> = ({modal}) => {
	const {data} = useQuery(SAML_SETTINGS);
	const {org} = useMyUser();
	const baseVal = useMemo(
		() => ({
			...(data?.samlSettings ?? {domains: [], disableRequestedAuthnContext: false}),
			ssoOnly: org?.options?.ssoOnly ?? false,
		}),
		[data?.samlSettings, org?.options?.ssoOnly]
	);
	const {flush, val, inputFunc, changes} = useDirtyCopy(baseVal);

	const [updateSamlSettings, {loading}] = useMutationToast(UPDATE_SAML_SETTINGS);
	const [updateOrg, {loading: orgLoading}] = useMutationToast(UPDATE_ORG);

	const invalidInputs = useMemo(
		() =>
			val.certificate === "" ||
			val.issuerUrl === "" ||
			!val.service ||
			val.sloUrl === "" ||
			val.ssoUrl === "" ||
			val.domains.length === 0,
		[val]
	);

	const handleSave = async (close: ModalData["close"]) => {
		const {ssoOnly, ...otherChanges} = val;
		delete otherChanges.signingCertificate;

		if (changes.ssoOnly !== undefined) await updateOrg({variables: {changes: {ssoOnly}}});
		if (Object.keys(otherChanges)?.length)
			await updateSamlSettings({variables: {settings: deleteTypename(otherChanges)}});

		flush();
		close();
	};

	return (
		<ConfirmModal
			modal={modal}
			title="Single Sign-On"
			size="large"
			confirmText="Save"
			disabled={invalidInputs || !changes}
			loading={orgLoading || loading}
			onConfirm={handleSave}
		>
			<Text
				label="Single Sign-In URL:"
				placeholder="Enter your ID provider's SSO endpoint URL"
				{...inputFunc("ssoUrl")}
			/>
			<Text
				label="Single Logout URL:"
				placeholder="Enter your ID provider's SSO SLC endpoint URL"
				{...inputFunc("sloUrl")}
			/>
			<Text
				label="Issuer URL:"
				placeholder="Enter your ID provider's issuer URL"
				{...inputFunc("issuerUrl")}
			/>
			<EditableSelect placeHolder="Enter Domains" label="Valid Domains" {...inputFunc("domains")} />
			<Select
				placeholder="Select a SSO Platform"
				label="SSO Platforms"
				options={[
					{
						value: "OKTA",
						label: "Okta",
					},
					{
						value: "ONELOGIN",
						label: "OneLogin",
					},
					{
						value: "AZUREAD",
						label: "Azure AD",
					},
				]}
				{...inputFunc("service")}
			/>
			<InputRow position="left" className={styles.inputContainer}>
				<Span3>Having issues with a particular authentication context?</Span3>
			</InputRow>
			<Checkbox
				className={styles.inputContainer}
				{...inputFunc("disableRequestedAuthnContext")}
				label="Disable the Requested Authentication Context, and allow ADFS to make its own decision"
				labelClassName={styles.label}
			/>
			<Checkbox
				className={styles.inputContainer}
				{...inputFunc("ssoOnly")}
				label="Restrict login to SSO only"
				labelClassName={styles.label}
			/>
			<Text
				className={styles.inputContainer}
				placeholder="Copy and paste your ID provider's entire x.509 Certificate here, including BEGIN and END lines."
				type="textarea"
				label="Your Public Certificate"
				{...inputFunc("certificate")}
			/>

			<h3 className="space">Provider SSO Settings (Disabled)</h3>
			<Text
				disabled
				label="Our SSO URL"
				value={`${config.appUrl}/login/ssoV2/${org.id}/callback`}
				onChange={() => undefined}
			/>
			<Text
				disabled
				label="Our SLO URL"
				value={`${config.appUrl}/logout/ssoV2/${org.id}`}
				onChange={() => undefined}
			/>
			<Text
				disabled
				label="Our Entity ID:"
				value={`${config.appUrl}/login/ssoV2/${org.id}/metadata`}
				onChange={() => undefined}
			/>
			<Text
				type="textarea"
				disabled
				label="Our Public Certificate:"
				value={val.signingCertificate}
				onChange={() => undefined}
			/>
		</ConfirmModal>
	);
};
