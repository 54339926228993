import {DependencyList, useCallback} from "react";

import {Button, InputRow} from "../components/input";
import {Span} from "../components/text";
import {BaseModalArgs, CloseFunc, ModalArgs, ModalHook, RenderItem, useModal} from ".";
import {Color} from "../types";

export interface ConfirmArgs<T> extends BaseModalArgs<T> {
	body: RenderItem<T>;
	confirming?: boolean;
	onConfirm: (close: CloseFunc<T>) => void;
	confirmText?: string;
	confirmColor?: Color;
	confirmDisabled?: boolean;
	size?: "small" | "large" | "medium";
	title: string;
}

export function useConfirmModal<T>(
	render: (ops: ModalHook<T>) => ConfirmArgs<T>,
	deps: DependencyList
): ModalHook<T> {
	const confirmModal = useCallback((ops: ModalHook<T>): ModalArgs<T> => {
		const {
			confirmColor,
			confirmText = "Continue",
			confirmDisabled,
			confirming,
			onConfirm,
			title,
			...rest
		} = render(ops);
		return {
			...rest,
			header: <h3>{title}</h3>,
			footer: (
				<InputRow position="between">
					<Button
						onClick={ops.close}
						value="Cancel"
						invert
						color="black"
						border={false}
						disabled={confirming}
					/>
					<Button
						autoFocus
						onClick={() => onConfirm(ops.close)}
						value={confirmText}
						loading={confirming}
						color={confirmColor}
						disabled={confirmDisabled}
					/>
				</InputRow>
			),
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
	return useModal(confirmModal, [confirmModal]);
}

interface ConfirmDeleteArgs<T>
	extends Omit<
		ConfirmArgs<T>,
		"confirming" | "onConfirm" | "body" | "confirmColor" | "confirmText" | "title"
	> {
	what: string;
	onDelete: (close: CloseFunc<T>) => void;
	deleting?: boolean;
}

export function useConfirmDeleteModal<T>({
	what,
	onDelete,
	deleting,
	onClose,
	onOpen,
}: ConfirmDeleteArgs<T>): ModalHook<T> {
	return useConfirmModal(
		() => ({
			onClose,
			onOpen,
			onConfirm: onDelete,
			confirming: deleting,
			confirmColor: "pink",
			confirmText: "Delete",
			title: "Confirm Delete",
			body: <Span>Are you sure you want to delete {what}?</Span>,
		}),
		[deleting, onClose, onDelete, onOpen, what]
	);
}

interface ConfirmRevokeArgs<T>
	extends Omit<ConfirmArgs<T>, "body" | "confirmColor" | "confirmText" | "title"> {
	what: string;
}

export function useConfirmRevokeModal<T>({
	what,
	onConfirm,
	confirming,
	onClose,
	onOpen,
}: ConfirmRevokeArgs<T>): ModalHook<T> {
	return useConfirmModal(
		() => ({
			onClose,
			onOpen,
			onConfirm,
			confirming,
			confirmColor: "pink",
			confirmText: "Revoke",
			title: "Confirm Revoke",
			body: <Span>Are you sure you want to revoke {what}?</Span>,
		}),
		[confirming, onClose, onConfirm, onOpen, what]
	);
}

interface ConfirmCloseArgs<T>
	extends Omit<ConfirmArgs<T>, "onConfirm" | "body" | "confirmColor" | "confirmText" | "title"> {
	onConfirm: (close: CloseFunc<T>) => void;
	deleting?: boolean;
}

export function useConfirmCloseModal<T>({onConfirm, onClose, onOpen}: ConfirmCloseArgs<T>): ModalHook<T> {
	return useConfirmModal(
		() => ({
			onClose,
			onOpen,
			onConfirm: onConfirm,
			confirmColor: "pink",
			confirmText: "Close without Saving",
			title: "Confirm Close",
			body: <Span>You have unsaved changes. Are you sure you want to close this window without saving?</Span>,
		}),
		[onClose, onConfirm, onOpen]
	);
}
