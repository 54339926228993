import classNames from "classnames";
import dayjs, {Dayjs} from "dayjs";
import React, {ReactElement, ReactNode, useCallback, useMemo, useState} from "react";

import {Button, Input, InputComponent, InputRow, Separator, Switch, useId} from "../index";
import {Icon} from "../../images";
import {Span, Span3} from "../../text";
import {ToggleGroup} from "../../toggle";
import {IntervalPicker} from "./date-picker-multiple";
import {AlerterWidget} from "../../alerter";
import {MonthNavigator} from "./month-navigator";
import {YearNavigator} from "./year-navigator";

import styles from "./date-range.module.scss";

type PresetIntervalType = "Today" | "1D" | "7D" | "1M" | "2M" | "3M" | "1Y" | "YTD" | "Max";

export type DateIntervalType = PresetIntervalType | [Dayjs, Dayjs | undefined] | undefined;
export const DateIntervalOptions = [
	{value: "Today", label: "Today"},
	{value: "7D", label: "7D"},
	{value: "1M", label: "1M"},
	{value: "2M", label: "2M"},
	{value: "3M", label: "3M"},
	{value: "1Y", label: "1Y"},
	{value: "YTD", label: "YTD"},
] as const;

export const ComparisonPresetOptions = [
	{value: "1D", label: "1D"},
	{value: "7D", label: "7D"},
	{value: "1M", label: "1M"},
	{value: "2M", label: "2M"},
	{value: "3M", label: "3M"},
	{value: "1Y", label: "1Y"},
] as const;

export const formatDateInterval = (start: Dayjs | undefined, end: Dayjs | undefined) => {
	if (start?.isSame(end, "day")) return `${start?.formatAs("shortDate")}`;
	if (start?.isSame(end, "week") && start?.endOf("week").isSame(end, "day"))
		return `Week ${start?.format("w, YYYY")}`;
	if (start?.isSame(end, "month") && start?.endOf("month").isSame(end, "day"))
		return `${start?.format("MMM YYYY")}`;
	if (start?.isSame(end, "year") && start?.endOf("year").isSame(end, "day"))
		return `${start?.format("YYYY")}`;
	return `${start?.formatAs("shortDate")} - ${end?.formatAs("shortDate")}`;
};
export const formatDateIntervalShort = (start: Dayjs | undefined, end: Dayjs | undefined) => {
	if (start?.isSame(end, "day")) return `${start?.formatAs("shortDate")}`;
	if (start?.isSame(end, "week") && start?.endOf("week").isSame(end, "day"))
		return `Wk. ${start?.format("w, YYYY")}`;
	if (start?.isSame(end, "month") && start?.endOf("month").isSame(end, "day"))
		return `${start?.format("MMM YYYY")}`;
	if (start?.isSame(end, "year") && start?.endOf("year").isSame(end, "day"))
		return `Yr. ${start?.format("YYYY")}`;
	if (start?.startOf("month").isSame(start, "day") && end?.endOf("month").isSame(end, "day"))
		return `${start?.format("MMM D")} - ${end?.format("MMM D, YYYY")}`;
	return `${start?.formatAs("shortDate")} - ${end?.formatAs("shortDate")}`;
};

const getUnit = int => {
	if (["1M", "2M", "3M"].includes(int)) return "month";
	if (["1Y", "Max"].includes(int)) return "year";
	if (int === "7D") return "week";
	return "day";
};
const getIntervalLength = int => {
	if (int === "7D") int = "1W";
	const l = int?.slice(0, 1);
	return isNaN(l) ? undefined : parseInt(l, 10);
};

const getValidPreset = (preset: PresetIntervalType | undefined, isComparison: boolean) => {
	const unit = {year: "Y", month: "M", day: "D"}[getUnit(preset)];

	if (isComparison) {
		return (ComparisonPresetOptions.find(opt => opt.value === preset)
			? preset
			: `1${unit}`) as PresetIntervalType;
	}

	return (DateIntervalOptions.find(opt => opt.value === preset) ? preset : "Today") as PresetIntervalType;
};

const subtractIntervalFromDate = (date: Dayjs | undefined, interval: PresetIntervalType): Dayjs => {
	const d = date || dayjs();
	if (interval === "1D") return d.add(-1, "day").startOf("day");
	if (interval === "7D") return d.add(-7, "day").startOf("day");
	if (interval === "1M") return d.add(-1, "month").startOf("month");
	if (interval === "2M") return d.add(-2, "month").startOf("month");
	if (interval === "3M") return d.add(-3, "month").startOf("month");
	if (interval === "1Y") return d.add(-1, "year").startOf("year");
	return d;
};

type Range = {start: Dayjs; end: Dayjs};
type IntervalUnit = "day" | "week" | "month" | "year";

export const getCustomRange = (
	min: Dayjs | undefined,
	customDate: DateIntervalType,
	unit?: IntervalUnit
): Range | undefined => {
	if (!customDate) return undefined;
	const current = dayjs().endOf("day");
	if (customDate === "Today") return {start: dayjs().startOf("day"), end: current};
	if (customDate === "Max") return {start: (min || dayjs()).startOf("day"), end: current};
	const end = dayjs().endOf(unit || "day");
	const sod = end.startOf(unit || "day");
	if (customDate === "1D") return {start: sod, end};
	if (customDate === "7D") return {start: end.startOf("week"), end};
	if (customDate === "1M") return {start: end.startOf("month"), end};
	if (customDate === "2M") return {start: end.add(-1, "month").startOf("month"), end};
	if (customDate === "3M") return {start: end.add(-2, "month").startOf("month"), end};
	if (customDate === "1Y") return {start: end.startOf("year"), end};
	if (customDate === "YTD") return {start: dayjs().startOf("year").startOf("year"), end: current};
	return {
		start: dayjs(customDate[0]),
		end: customDate[1] ? dayjs(customDate[1]) : dayjs(customDate[0])?.endOf("day"),
	};
};

export const useCustomRange = (customDate: DateIntervalType, min: Dayjs | undefined): Range | undefined =>
	useMemo(() => getCustomRange(min, customDate), [customDate, min]);

export interface DateIntervalPickerProps extends InputComponent<DateIntervalType> {
	min: Dayjs;
	max?: Dayjs;
	showPresets?: boolean;
	showComparison?: boolean;
	comparisonValue?: DateIntervalType;
	onComparisonChange?: (value: DateIntervalType) => void;
	showClear?: boolean;
	labelText?: string;
}

type PickerValue = [Dayjs, Dayjs | undefined] | undefined;

export interface DateTimePickerProps extends Omit<DateIntervalPickerProps, "value" | "onChange"> {
	labelComponent?: ReactNode;
	value: PickerValue;
	preset?: PresetIntervalType;
	comparisonValue: PickerValue;
	onChange: (value: PickerValue) => void;
	onPresetValueChange: (value?: PresetIntervalType) => void;
	showPresets?: boolean;
	showClear?: boolean;
}

const DatePicker = ({
	id: maybeId,
	value,
	preset,
	comparisonValue,
	min,
	max,
	labelComponent,
	onChange,
	onComparisonChange,
	onPresetValueChange,
	showPresets,
	showClear,
	showComparison,
	...props
}: DateTimePickerProps): ReactElement => {
	const id = useId(maybeId);
	const [isInternalOpen, setIsInternalOpen] = useState(false);
	const [comparison, enableComparison] = useState(false);
	const [tempValue, setTempValue] = useState<PickerValue>(value);
	const [comparisonTempValue, setComparisonTempValue] = useState<PickerValue>(undefined);
	const [presetValue, setPresetValue] = useState<PresetIntervalType | undefined>(preset);
	const [displayDate, setDisplayDate] = useState((value?.[0] || dayjs()).startOf("month"));
	const [comparisonDisplayDate, setComparisonDisplayDate] = useState(
		(comparisonTempValue?.[0] || dayjs()).startOf("month")
	);
	const unit = getUnit(presetValue);

	const onPickerDateChange = v => {
		setTempValue(v);
	};

	const onComparisonDateChange = v => {
		setComparisonTempValue(v);
	};

	const update = useCallback(
		(preset, comparison) => {
			const unit = getUnit(preset);
			const range = getCustomRange(min, preset, unit);
			let displayDate = range?.end && unit !== "year" ? range.end : dayjs();
			displayDate = displayDate.startOf(["day", "week"].includes(unit) ? "month" : "year");

			setTempValue(
				range ? [range.start.startOf(unit), range.end.endOf(unit).subtract(1, "second")] : undefined
			);
			setDisplayDate(displayDate);

			if (!comparison) return;

			const comparisonRange = range
				? {start: subtractIntervalFromDate(range?.start, preset), end: range?.start?.add(-1, "second")}
				: null;
			let comparisonDisplayDate = comparisonRange?.end && unit !== "year" ? comparisonRange.end : dayjs();
			comparisonDisplayDate = comparisonDisplayDate.startOf(
				["day", "week"].includes(unit) ? "month" : "year"
			);

			setComparisonTempValue(comparisonRange ? [comparisonRange.start, comparisonRange.end] : undefined);
			setComparisonDisplayDate(comparisonDisplayDate);
		},
		[min]
	);

	const onPresetChange = useCallback(
		v => {
			setPresetValue(v);
			update(v, comparison);
		},
		[update, comparison]
	);

	const handleClear = useCallback(() => {
		setTempValue(undefined);
		setComparisonTempValue(undefined);
		setPresetValue(undefined);
		enableComparison(!!comparisonValue);
	}, [comparisonValue]);

	const handleOnOpen = useCallback(
		v => {
			setIsInternalOpen(v);

			if (!v) {
				handleClear();
				return;
			}

			setPresetValue(getValidPreset(preset, comparison));
			setTempValue(value);
			setComparisonTempValue(comparisonValue);
			setDisplayDate((value?.[1] ?? value?.[0] ?? dayjs()).startOf("month"));
			setComparisonDisplayDate((comparisonValue?.[1] ?? comparisonValue?.[0] ?? dayjs()).startOf("month"));
		},
		[value, comparisonValue, preset, handleClear, comparison]
	);

	const handleSave = useCallback(() => {
		if (comparisonTempValue?.[1]?.isAfter(tempValue?.[1])) {
			onChange(comparisonTempValue);
			onComparisonChange?.(tempValue);
		} else {
			onChange(tempValue);
			onComparisonChange?.(comparisonTempValue);
		}
		onPresetValueChange(presetValue);
		setIsInternalOpen(false);
	}, [onChange, onComparisonChange, tempValue, comparisonTempValue, presetValue, onPresetValueChange]);

	const handleCancel = () => {
		setIsInternalOpen(false);
		handleClear();
	};

	const onComparisonSwitch = useCallback(
		v => {
			enableComparison(v);

			if (!v) {
				setComparisonTempValue(undefined);
			}

			const validPreset = getValidPreset(presetValue, v);

			if (validPreset !== presetValue) {
				setPresetValue(validPreset);
			}

			update(validPreset, v);
		},
		[presetValue, update]
	);

	const renderNavigator = useCallback(
		({displayDate, onChange}) => {
			if (["day", "week"].includes(unit)) {
				return <MonthNavigator value={displayDate} onChange={onChange} max={max} min={min} />;
			}
			return (
				<YearNavigator
					value={displayDate}
					onChange={onChange}
					max={max}
					min={min}
					step={unit === "year" ? 12 : 1}
					intervalTxt={
						unit === "year"
							? `${dayjs().add(-11, "years").format("YYYY")} - ${dayjs().format("YYYY")}`
							: undefined
					}
				/>
			);
		},
		[min, max, unit]
	);

	return (
		<Input id={id} {...props}>
			<AlerterWidget
				labelComponent={labelComponent}
				isOpen={isInternalOpen}
				onOpen={handleOnOpen}
				className={styles.rangeAlerter}
			>
				<div className={styles.datepicker}>
					{showPresets && (
						<div className={styles.pickerHeader}>
							{comparison && <Span3 color={"grey"}>Comparison Interval</Span3>}
							<ToggleGroup
								className={classNames(styles.presets)}
								compact
								options={comparison ? ComparisonPresetOptions : DateIntervalOptions}
								onChange={onPresetChange}
								value={presetValue}
							/>
						</div>
					)}
					<div className={styles.pickersContainer}>
						{comparison && (
							<>
								<div>
									{renderNavigator({displayDate: comparisonDisplayDate, onChange: setComparisonDisplayDate})}
									<IntervalPicker
										value={comparisonTempValue}
										displayDate={comparisonDisplayDate}
										onChange={onComparisonDateChange}
										min={min}
										max={max}
										unit={unit}
										intervalLength={getIntervalLength(presetValue)}
									/>
								</div>
								<Separator />
							</>
						)}
						<div>
							{renderNavigator({displayDate, onChange: setDisplayDate})}
							<IntervalPicker
								value={tempValue}
								displayDate={displayDate}
								onChange={onPickerDateChange}
								min={min}
								max={max}
								unit={unit}
								intervalLength={getIntervalLength(presetValue)}
							/>
						</div>
					</div>
					<InputRow className={styles.divider} position="between">
						<InputRow>
							{showComparison && (
								<Switch label="Comparison" value={comparison} onChange={onComparisonSwitch} />
							)}
							{showClear && <Button value="Clear" invert onClick={handleClear} />}
						</InputRow>
						<InputRow>
							<Button value="Cancel" invert onClick={handleCancel} />
							<Button value="Save" onClick={handleSave} />
						</InputRow>
					</InputRow>
				</div>
			</AlerterWidget>
		</Input>
	);
};

export const DateIntervalPicker = ({
	value,
	comparisonValue,
	onChange,
	onComparisonChange,
	min,
	max,
	className,
	showPresets,
	showClear,
	showComparison,
	labelText,
	id: maybeId,
	...props
}: DateIntervalPickerProps): ReactElement => {
	const range = useCustomRange(value, min);
	const comparisonRange = useCustomRange(comparisonValue, min);
	const [preset, setPreset] = useState<PresetIntervalType | undefined>("Today");
	const {start, end} = range || {};
	const {start: comparisonStart, end: comparisonEnd} = comparisonRange || {};
	const id = useId(maybeId);

	return (
		<Input id={id} {...props}>
			<DatePicker
				labelComponent={
					<div className={classNames(className, styles.dateIntervalContainer)}>
						<Icon icon="calendar" className={styles.icon} />
						{comparisonValue && (
							<Span className={styles.text}>
								{labelText ??
									`${formatDateInterval(start, end)} vs ${formatDateInterval(
										comparisonStart,
										comparisonEnd
									)}`}
							</Span>
						)}
						{!comparisonValue && ((start && end) || labelText) && (
							<Span className={styles.text}>
								{labelText ??
									(value === "Max"
										? "Custom Range"
										: start?.isSame(end, "day")
										? start?.formatAs("shortDate")
										: `${start?.formatAs("shortDate")} - ${end?.formatAs("shortDate")}`)}
							</Span>
						)}
					</div>
				}
				showPresets={showPresets}
				showClear={showClear}
				showComparison={showComparison}
				value={start ? [start, end] : undefined}
				preset={preset}
				comparisonValue={comparisonStart ? [comparisonStart, comparisonEnd] : undefined}
				min={min}
				max={max}
				onChange={onChange}
				onComparisonChange={onComparisonChange}
				onPresetValueChange={setPreset}
			/>
		</Input>
	);
};
