import {ReactElement, useMemo, useState} from "react";
import classnames from "classnames";
import dayjs, {Dayjs} from "dayjs";

import {Card} from "../../components/card";
import {CalendarItem, CalendarProps, sortDate} from ".";
import {Span3} from "../../components/text";
import {InputRow, SmallButton} from "../../components/input";
import {useEventModal} from "./event-modal";
import {EventList} from "./calendar-items";
import {Loading} from "../../components/loading";

import styles from "./schedule.module.scss";

export const CalendarMonth = ({selectedDay, data, loading, setDayView}: CalendarProps): ReactElement => {
	const weekdays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
	const currentDays = useMemo(() => selectedDay.getWeeksInRange().flatMap(week => week.days), [selectedDay]);
	const today = dayjs();
	const [itemData, setItemData] = useState<CalendarItem>();

	const {open: openEventModal} = useEventModal({calendarItem: itemData});

	const eventHandler = (data: CalendarItem) => {
		setItemData(data);
		openEventModal();
	};

	const getItemsOnDate = (date: Dayjs): ReactElement => {
		const start = date.startOf("day");
		const end = start.add(1, "day");

		const final = data.filter(x => x.date?.isBetween(start, end, null, "[)")).sort(sortDate);

		return (
			<div className={styles.events}>
				{final.slice(0, 2).map(item => (
					<div
						className={classnames(styles.item, styles[item.type])}
						key={`${item.type}-${item.id}`}
						onClick={() => eventHandler(item)}
					>
						<EventList data={item} />
					</div>
				))}
				{final.length > 2 && (
					<InputRow position="right">
						<SmallButton
							color="black"
							value={`+${final.length - 2} more`}
							onClick={() => setDayView(date)}
							border={false}
							invert
						/>
					</InputRow>
				)}
			</div>
		);
	};

	return (
		<Card className={styles.monthlyCalendar}>
			{loading && <Loading position="absolute" />}
			<div className={styles.days}>
				{currentDays.map((day, index) => (
					<div
						key={day.valueOf()}
						className={classnames(styles.day, !day.isSame(selectedDay, "month") && styles.notCurrent)}
					>
						<div className={styles.dateText}>
							<Span3 bold className={classnames(day.isSame(today, "day") && styles.todaysDay)}>
								{day.format("D")}
							</Span3>
							<Span3 color="grey">{weekdays[index]}</Span3>
						</div>
						{getItemsOnDate(day)}
					</div>
				))}
			</div>
		</Card>
	);
};
