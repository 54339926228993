import {ReactElement, useState, useCallback, useMemo} from "react";
import {useQuery} from "@apollo/client";

import {
	Button,
	DropdownButton,
	InputHeader,
	Text,
	Group,
	GroupedDropdown,
	Validate,
	required,
} from "../../components/input";
import {downloadCsv} from "../../utils/csv";
import {EmptyList} from "../../components/empty-list";
import {CREATE_GROUP, GET_GROUPS, Group as GroupType, useSimpleUsers} from "../../data";
import {Group as GroupItem} from "./group";
import {Loading} from "../../components/loading";
import {NewModal as Modal, useNewModal as useModal} from "../../modals";
import {useMutationToast} from "../../toast";

const sortOptions = [
	{
		value: "nameAsc",
		label: "A to Z Name",
		sort: (a: GroupType, b: GroupType) => a.name.localeCompare(b.name),
	},
	{
		value: "nameDesc",
		label: "Z to A Name",
		sort: (a: GroupType, b: GroupType) => b.name.localeCompare(a.name),
	},
	{
		value: "usersAsc",
		label: "Users Ascending",
		sort: (a: GroupType, b: GroupType) => a.users.length - b.users.length,
	},
	{
		value: "usersDesc",
		label: "Users Descending",
		sort: (a: GroupType, b: GroupType) => b.users.length - a.users.length,
	},
	{
		value: "virtualAssistantsAsc",
		label: "Virtual Assistants Ascending",
		sort: (a: GroupType, b: GroupType) => a.vas - b.vas,
	},
	{
		value: "virtualAssistantsDesc",
		label: "Virtual Assistants Descending",
		sort: (a: GroupType, b: GroupType) => b.vas - a.vas,
	},
	{
		value: "queuesAsc",
		label: "Collections Ascending",
		sort: (a: GroupType, b: GroupType) => a.queues - b.queues,
	},
	{
		value: "queuesDesc",
		label: "Collections Descending",
		sort: (a: GroupType, b: GroupType) => b.queues - a.queues,
	},
] as const;

type FilterTypes =
	| "createCollections"
	| "approveCollections"
	| "addContent"
	| "sendAll"
	| "manageGroups"
	| "setPrimaryAdmin";

const filterOptions: Group<FilterTypes, string | number>[] = [
	{
		label: "Create Collections",
		value: "createCollections",
		options: [{value: "enabled", label: "Enabled"}],
	},
	{
		label: "Approve Collections",
		value: "approveCollections",
		options: [{value: "enabled", label: "Enabled"}],
	},
	{
		label: "Add Content To Collections",
		value: "addContent",
		options: [{value: "enabled", label: "Enabled"}],
	},
	{
		label: "Send Collections To Everyone",
		value: "sendAll",
		options: [{value: "enabled", label: "Enabled"}],
	},
	{
		label: "Manage Groups",
		value: "manageGroups",
		options: [{value: "enabled", label: "Enabled"}],
	},
	{
		label: "Set Primary Admin",
		value: "setPrimaryAdmin",
		options: [{value: "enabled", label: "Enabled"}],
	},
];

export const Groups = (): ReactElement => {
	const [createGroup, {loading: adding}] = useMutationToast(CREATE_GROUP);
	const [valid, setValid] = useState(true);
	const [name, setName] = useState("");
	const {loading, data} = useQuery(GET_GROUPS);
	const [search, setSearch] = useState("");
	const [sort, setSort] = useState<typeof sortOptions[number]["value"]>("nameAsc");
	const [filter, setFilter] = useState<Record<FilterTypes, (string | number)[]>>({
		createCollections: [],
		approveCollections: [],
		addContent: [],
		sendAll: [],
		manageGroups: [],
		setPrimaryAdmin: [],
	});
	const {modal, open, close} = useModal({});
	const handleAdd = useCallback(
		() =>
			createGroup({variables: {name}}).then(() => {
				setName("");
				close();
			}),
		[close, createGroup, name]
	);

	const searchTrimmed = search.trim();
	const searchLC = searchTrimmed.toLowerCase();

	const groups = useMemo(() => {
		const filters: ((group: GroupType) => boolean)[] = [];
		if (searchLC) filters.push(group => group?.name?.toLowerCase()?.includes(searchLC));
		if (filter.createCollections.length) filters.push(g => g.createCollections);
		if (filter.approveCollections.length) filters.push(g => g.approveCollections);
		if (filter.addContent.length) filters.push(g => g.addContent);
		if (filter.sendAll.length) filters.push(g => g.sendAll);
		if (filter.manageGroups.length) filters.push(g => g.manageGroups);
		if (filter.setPrimaryAdmin.length) filters.push(g => g.setPrimaryAdmin);
		filters.push(group => group?.name !== "Everyone");

		const filteredGroups =
			data?.groups.filter(u => {
				for (const f of filters) if (!f(u)) return false;
				return true;
			}) ?? [];

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return filteredGroups.sort(sortOptions.find(o => o.value === sort)!.sort);
	}, [data, sort, searchLC, filter]);

	const handleExport = useCallback(() => {
		const columns = ["Group Name", "User First Name", "User Last Name", "User Role", "Virtual Assistants"];
		const csvData: string[][] = [];

		groups.forEach(group => {
			group.users.forEach(user => {
				csvData.push([
					group.name,
					user.firstName,
					user.lastName,
					user.role.toLowerCase(),
					user.virtualAssistant,
				]);
			});
		});

		downloadCsv(csvData, columns, "groups_export.csv");
	}, [groups]);

	const isEmpty = groups.length === 0;

	const allUsers = useSimpleUsers({limit: null, filter: {status: ["active"]}});
	const usersObject = useMemo(() => allUsers.reduce((acc, val) => ({...acc, [val.id]: val}), {}), [allUsers]);

	return (
		<div>
			<InputHeader>
				<>
					<Text icon="search" value={search} onChange={setSearch} placeholder="Search" />

					<GroupedDropdown groups={filterOptions} onChange={setFilter} value={filter} icon="filter" invert />

					<DropdownButton
						options={sortOptions.map(o => ({
							label: o.label,
							onClick: () => setSort(o.value),
							selected: sort === o.value,
						}))}
						icon="sort"
						invert
					/>
				</>
				<>
					<Button onClick={open} value="Create Group" icon="create-group" color="pink" />
					<Button onClick={handleExport} value="Export" icon="export" arrow="down" />
				</>
			</InputHeader>

			{loading ? (
				<Loading position="center" />
			) : (
				<>
					{groups.map(group => (
						<GroupItem
							users={allUsers}
							usersObject={usersObject}
							key={group.id}
							groups={data?.groups}
							group={group}
						/>
					))}
					{isEmpty && <EmptyList text={search ? "No matches found" : "No items"} />}
				</>
			)}
			<Modal title="Add New Group" modal={modal}>
				<Validate setStatus={setValid}>
					<Text label="Group Name" onChange={setName} value={name} validate={required} autoFocus />
					<Button large value="Add New Group" disabled={!valid} loading={adding} onClick={handleAdd} />
				</Validate>
			</Modal>
		</div>
	);
};
