import {ReactElement, useCallback, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import classnames from "classnames";

import {Collapsible} from "../../components/card";
import {
	Button,
	Checkbox,
	InputHeader,
	Select,
	Text,
	MultiColumn,
	Separator,
	required,
	validEmail,
	Validate,
} from "../../components/input";
import {UserAvatar} from "../../components/user-avatar";
import {Connection} from "../../components/social";
import {
	DEACTIVATE_USERS,
	REINVITE_USERS,
	REMIND_RECONNECT,
	UPDATE_USER,
	User as UserType,
	roleOptions,
	useExpiredSocialMediaUsers,
	useGroupList,
} from "../../data";
import {EmptyComponent} from "../../types";
import {Badge} from "../../components/badge";
import {P, P2, Span, Span2} from "../../components/text";
import {useConfirmModal} from "../../modals";
import {useMutationToast, useToast} from "../../toast";
import {useDirtyCopy} from "../../dirty-copy";
import {validMaxLength} from "../../components/input/validation";

import styles from "./user.module.scss";

interface UserComponent extends EmptyComponent {
	user: UserType;
	onSelect: (id: number, value: boolean) => void;
	selected: boolean;
}

const body = (message: string, user: UserType) => (
	<div className={styles.confirm}>
		<P2>{message}</P2>
		<P2 bold>{user.fullName}</P2>
		<P color="grey">Please confirm below.</P>
		<P2 bold>{user.email}</P2>
	</div>
);

export const User = ({user, onSelect, selected}: UserComponent): ReactElement => {
	const [valid, setValid] = useState(true);
	const {groups: groupList} = useGroupList();
	const toast = useToast();
	const [deactivateUsers, {loading: deactivating}] = useMutationToast(DEACTIVATE_USERS);
	const [reinviteUsers, {loading: reinviting}] = useMutationToast(REINVITE_USERS);
	const [remindReconnect, {loading: remindingReconnect}] = useMutationToast(REMIND_RECONNECT);
	const expiredUsers = useExpiredSocialMediaUsers();
	const needReconnect = expiredUsers.some(u => u.id === user.id);

	const [updateUser, {loading: saving}] = useMutationToast(UPDATE_USER);
	const {changes, discard, dirty, inputFunc} = useDirtyCopy(user);
	const {state} = useLocation();
	const navigate = useNavigate();

	// prettier-ignore
	const confirmDeactivate = useCallback(
		close => deactivateUsers({variables: {ids: [user.id]}}).then(() => close()),
		[user.id, deactivateUsers]
	);
	const confirmResend = useCallback(
		close =>
			reinviteUsers({
				variables: {ids: [user.id]},
				onCompleted: () =>
					toast({
						text:
							"Your welcome email has been sent. Please allow a few minutes for it to appear in the inbox.",
						color: "green",
					}),
			}).then(() => close()),
		[reinviteUsers, user.id, toast]
	);
	const confirmReconnect = useCallback(
		close => remindReconnect({variables: {ids: [user.id]}}).then(() => close()),
		[remindReconnect, user.id]
	);
	const {open: deactivate} = useConfirmModal(
		() => ({
			onConfirm: confirmDeactivate,
			confirmColor: "pink",
			confirmText: "Deactivate",
			icon: "warning",
			body: body("You're about to de-activate", user),
			title: "De-Activate User",
			confirming: deactivating,
		}),
		[confirmDeactivate, deactivating, user]
	);
	const {open: resend} = useConfirmModal(
		() => ({
			onConfirm: confirmResend,
			icon: "email",
			body: body("You're about to re-send an invitation to", user),
			title: "Re-send Invite",
			confirming: reinviting,
		}),
		[confirmResend, reinviting, user]
	);
	const {open: reconnect} = useConfirmModal(
		() => ({
			onConfirm: confirmReconnect,
			icon: "email",
			body: body(
				`You're about to resend a reminder that the following user has a disconnected or expired social media accounts.`,
				user
			),
			title: "Re-send Reconnect Reminder",
			confirming: remindingReconnect,
		}),
		[confirmReconnect, remindingReconnect, user]
	);
	const saveChanges = useCallback(() => {
		if (!changes) return;
		const lChanges: Omit<typeof changes, "role"> & {role?: string} = {...changes};
		if (changes.role) lChanges.role = changes.role.toUpperCase();
		return updateUser({variables: {id: user.id, changes: lChanges}, onCompleted: discard});
	}, [changes, discard, user.id, updateUser]);
	const ref = useRef<HTMLDivElement>(null);
	const header = (
		<>
			<div className={styles.profile}>
				<Checkbox value={selected} onChange={value => onSelect(user.id, value)} className={styles.checkbox} />
				<UserAvatar userId={user.id} name text={roleOptions.find(r => r.value === user.role)?.label} />
			</div>
			<div className={classnames(styles.item, !user.virtualAssistant && styles.disabled)}>
				{user.virtualAssistant && <Badge color="blue" text="VA" />}
			</div>
			<div className={styles.item}>
				{user.status !== "pending" && (
					<>
						<Span2 bold>Last Share</Span2>
						<Span color={user.lastShared ? "grey" : "pink"}>
							{user.lastShared?.fromNow() ?? "Hasn't shared yet"}
						</Span>
					</>
				)}
			</div>
			<div className={styles.item}>
				{user.status === "pending" ? (
					<>
						<Span2 bold>Invitation Status</Span2>
						<Span color="grey">
							{" "}
							Invited {user.lastInvited ? user.lastInvited.fromNow() : user.created.fromNow()}
						</Span>
					</>
				) : (
					<>
						<Span2 bold>Last Online</Span2>
						<Span color="grey">{user.lastLogin?.fromNow() ?? "Never"}</Span>
					</>
				)}
			</div>
			<div className={styles.item}>
				<Button color="black" invert border={false} value="Resend Invite" onClick={resend} icon="refresh" />
			</div>
			<div className={styles.networks}>
				{user.status !== "pending" && (
					<div>
						<div className={styles.icons}>
							<Connection name="linkedin" connections={user.connections} />
							<Connection name="facebook" connections={user.connections} />
							<Connection name="twitter" connections={user.connections} />
						</div>
						{needReconnect && (
							<Button
								color="black"
								invert
								border={false}
								value="Reconnect Socials"
								onClick={reconnect}
								icon="refresh"
							/>
						)}
					</div>
				)}
			</div>
		</>
	);
	useEffect(() => {
		if (state?.id === user.id && ref.current) {
			ref.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
			navigate("/users/list", {replace: true});
		}
	}, [state, ref, user, navigate]);

	return (
		<Collapsible
			ref={ref}
			isDefaultOpen={state?.id === user.id}
			headerClassName={styles.header}
			header={header}
		>
			<Validate setStatus={setValid}>
				<MultiColumn>
					<Text
						type="text"
						label="First Name"
						placeholder={user.firstName}
						validate={[required, validMaxLength(200)]}
						{...inputFunc("firstName")}
					/>
					<Select options={roleOptions} label="Role" {...inputFunc("role")} />
					<Text
						type="text"
						label="Last Name"
						placeholder={user.lastName}
						validate={[required, validMaxLength(200)]}
						{...inputFunc("lastName")}
					/>
					<Select multi options={groupList} label="Groups" pills {...inputFunc("groups")} />
					<Text
						type="text"
						label="Email"
						placeholder={user.email}
						validate={[required, validEmail]}
						{...inputFunc("email")}
					/>
					<Checkbox
						label="Virtual Assistant"
						text="Allows other admins to post on this account"
						{...inputFunc("virtualAssistant")}
					/>
				</MultiColumn>
				<InputHeader>
					<Button
						onClick={deactivate}
						icon="delete"
						invert
						value="De-activate Account"
						color="pink"
						border={false}
					/>
					<Button
						onClick={saveChanges}
						value="Save Changes"
						icon="check"
						disabled={!dirty || !valid}
						loading={saving}
					/>
				</InputHeader>
			</Validate>
			<Separator horizontal />
		</Collapsible>
	);
};
