import {BrowserClientReplayOptions, Options, TracePropagationTargets} from "@sentry/types";
import dayjs, {Dayjs} from "dayjs";

import {local} from "./local";
export interface Config {
	appUrl: string;
	apiHost: string;
	graphqlUrl: string;
	linkedinUrl: string;
	twitterUrl: string;
	facebookUrl: string;
	exportUrl: string;
	internalToolsUrl: string;
	intercomAppId: string;
	tracePropagationTargets: TracePropagationTargets;
	sentry: BrowserClientReplayOptions & Options;
	csAgent: "salesforce" | "intercom";
	salesforceURL: string;
	slackURL: string;
	salesforceConfig: {
		baseLiveAgentContentURL: string;
		deploymentId: string;
		buttonId: string;
		baseLiveAgentURL: string;
		eswLiveAgentDevName: string;
		isOfflineSupportEnabled: boolean;
	};
	PhotoEditorSDK: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	};
	classicExpirationDate: Dayjs;
	appcues: {
		enabled: boolean;
	};
	aptrinsic: {
		enabled: boolean;
		key: string;
	};
}

export type Environment = "dev" | "staging" | "prod";

const base: Config = {
	appUrl: "https://greg-dev.clearviewsocial.com",
	apiHost: "https://greg-dev.clearviewsocial.com/api/v2",
	graphqlUrl: "https://greg-dev.clearviewsocial.com/graphql",
	linkedinUrl: "https://greg-dev.clearviewsocial.com/api/v2/auth/connect/linkedin2",
	facebookUrl: "https://greg-dev.clearviewsocial.com/api/v2/auth/connect/facebook",
	twitterUrl: "https://greg-dev.clearviewsocial.com/api/v2/auth/connect/twitter",
	exportUrl: "https://api-dev.clearviewsocial.com/csv-email",
	internalToolsUrl: "https://greg-dev.clearviewsocial.com/internal",
	intercomAppId: "glwmtd8a",
	csAgent: "salesforce",
	salesforceURL: "https://profitsolv1.my.salesforce.com",
	salesforceConfig: {
		baseLiveAgentContentURL: "https://c.la1-core1.sfdc-lywfpd.salesforceliveagent.com/content",
		deploymentId: "5725f000000Uhqz",
		buttonId: "573RM0000000gYj",
		baseLiveAgentURL: "https://d.la1-core1.sfdc-lywfpd.salesforceliveagent.com/chat",
		eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04IRM00000067Mz2AI_18d664b0d78",
		isOfflineSupportEnabled: true,
	},
	slackURL:
		"https://slack.com/oauth/v2/authorize?client_id=6952557783490.6982335243330&scope=incoming-webhook,commands&user_scope=&redirect_uri=https://app.clearviewsocial.com/api/v2/auth/callback/slack-webhook",
	tracePropagationTargets: ["localhost", /^https:\/\/.*?\.clearviesocial\.com/],
	sentry: {
		dsn: "https://9b39c7a3f1d548dca008eaf0c710134a@o222288.ingest.sentry.io/4505466653507584",
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	},
	PhotoEditorSDK: {
		license: {
			api_token: "E_2G-v4xxaN06YVcm0yNGg",
			app_identifiers: ["*.clearviewsocial.com"],
			available_actions: [],
			domains: ["https://api.photoeditorsdk.com"],
			enterprise_license: false,
			expires_at: null,
			features: [
				"camera",
				"library",
				"export",
				"customassets",
				"whitelabel",
				"adjustment",
				"brush",
				"filter",
				"focus",
				"frame",
				"overlay",
				"sticker",
				"text",
				"textdesign",
				"transform",
			],
			issued_at: 1644871077,
			minimum_sdk_version: "1.0",
			owner: "Clearview Social, Inc.",
			platform: "HTML5",
			products: ["pesdk"],
			version: "2.4",
			signature:
				"iye/c1+oPWhjEyThv6mDX8w0tr9ACXnMMcGjFLPnbIVBPvfD+jIzPJNfb239CAUAsdd6tWrjhiTiQGRYz5nSdUvO61pz4S+o4dSSyoKnWZs1pFnK1jzpDzFfzf9AaXanye6i3Dc9V8dV28gFd/31v5GXyHCwEI9JAc/kGaD9MjKYslpxvPLYXPbisAINfVO3Xhcm/UPdIypB+7EUCd+dOGkBlb+m79TUmYZnoImJH6sQfkCPkaKrZmyfMgmrqtoFf9HqzRpkSp58N1fTLT5ve07gJ8O8lNH3mAlYQU8fbDKgAWASm6IzkNDTMgf5m7nWG9kazetyYU9d95VU4mcyv8ZVd48+9JdbBE1QE6/VsldOcvniSOErVCZFjMPwEpURhcndrJNtK7xHREapCQ1VeEAJLfATkreZOx7rTOn2lplFp1Zgkgot2JOjOP3um4rPTQTyWc8IPZUVed+wqcu1tQON1XXApU7sBXFaKW6wWOCALzP5zBXjxvhBswSDkDdGFLXxl1/wC7aq5wXaKaBAhb7CVHLec/W5Hf6qZqA2D35Dt6Fs+x1CPt4f5Fu3YuetVOqbQAGM2jYSTklBkqNEUe5tmobvAeTBYGuE9rtZuXXLQvtN4216IytuFkkDDQsn1B98bw7jAZ8bjyfZX9N70CLt3z7kz2sWbtLgnXY+BXk=",
		},
	},
	classicExpirationDate: dayjs("2024-09-30", "YYYY-MM-DD"),
	appcues: {
		enabled: true,
	},
	aptrinsic: {
		enabled: true,
		key: "AP-L7HSBAUJILCX-2",
	},
};

const envs: {[key in Environment]: Partial<Config>} = {
	dev: {
		appcues: {
			enabled: false,
		},
		aptrinsic: {
			enabled: false,
			key: "AP-L7HSBAUJILCX-2-3",
		},
	},
	staging: {
		appUrl: "https://redesign-dev.clearviewsocial.com",
		apiHost: "https://redesign-dev.clearviewsocial.com/api/v2",
		graphqlUrl: "https://redesign-dev.clearviewsocial.com/graphql",
		linkedinUrl: "https://redesign-dev.clearviewsocial.com/api/v2/auth/connect/linkedin2",
		facebookUrl: "https://redesign-dev.clearviewsocial.com/api/v2/auth/connect/facebook",
		twitterUrl: "https://redesign-dev.clearviewsocial.com/api/v2/auth/connect/twitter",
		internalToolsUrl: "https://redesign-dev.clearviewsocial.com/internal",
		slackURL:
			"https://slack.com/oauth/v2/authorize?client_id=6952557783490.6982335243330&scope=incoming-webhook,commands&user_scope=&redirect_uri=https://redesign-dev.clearviewsocial.com/api/auth/callback/slack-webhook",
		aptrinsic: {
			enabled: true,
			key: "AP-L7HSBAUJILCX-2-2",
		},
	},
	prod: {
		appUrl: "https://app.clearviewsocial.com",
		apiHost: "https://app.clearviewsocial.com/api/v2",
		graphqlUrl: "https://app.clearviewsocial.com/graphql",
		linkedinUrl: "https://app.clearviewsocial.com/api/v2/auth/connect/linkedin2",
		facebookUrl: "https://app.clearviewsocial.com/api/v2/auth/connect/facebook",
		twitterUrl: "https://app.clearviewsocial.com/api/v2/auth/connect/twitter",
		exportUrl: "https://api.clearviewsocial.com/csv-email",
		internalToolsUrl: "https://app.clearviewsocial.com/internal",
		sentry: {
			dsn: "https://9b39c7a3f1d548dca008eaf0c710134a@o222288.ingest.sentry.io/4505466653507584",
			tracesSampleRate: 0.1,
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
		},
		aptrinsic: {
			enabled: true,
			key: "AP-L7HSBAUJILCX-2",
		},
	},
};

const env = envs[process.env.REACT_APP_ENV || "dev"];
const config: Config = {...base, ...env, ...local};

export default config;
export {config};
