import dayjs, {Dayjs} from "dayjs";
import {useMemo, useState} from "react";
import {useQuery} from "@apollo/client";
import classNames from "classnames";

import {Button, InputRow, RadioGroup} from "../../components/input";
import {Icon} from "../../components/images";
import {ModalHook, useConfirmModal} from "../../modals";
import {Span, Span2} from "../../components/text";
import {DateTimePicker} from "../../components/input/date-time";
import {PeakTimeCalendar} from "../../components/input/date-time/date-time-picker";
import {useMutationToast, useToast} from "../../toast";
import {useDirtyCopy} from "../../dirty-copy";
import {GENERATE_PEAK_TIME, Service, accountNames} from "../../data";
import {GET_NETWORK_TIME_SLOTS} from "../../data/share";
import {Loading} from "../../components/loading";

import styles from "./schedule.module.scss";

export type ScheduleArg = Partial<Record<Service, NetworkShare>>;
export interface NetworkShare {
	scheduledFor: Dayjs | undefined;
	peakTime: boolean;
	id?: number;
}

export interface ScheduleModalProps {
	value: ScheduleArg;
	id: number;
	onConfirm: (value: ScheduleArg, close: () => void) => void;
	confirming?: boolean;
	peakTime?: boolean;
	maxDate?: Dayjs;
	loading?: boolean;
}

export interface BulkScheduleModalProps {
	value: Dayjs | undefined;
	onConfirm: (value: Dayjs | undefined, close: () => void) => void;
}

const options = [
	{value: false, label: "Peaktime™"},
	{value: true, label: "Custom date/time"},
] as const;

const defaultDate = dayjs().add(1, "day");

export function useScheduleModal<T>({
	confirming,
	loading: loadingParent,
	id,
	onConfirm,
	value,
	peakTime = true,
	maxDate,
}: ScheduleModalProps): ModalHook<T> {
	const [selectedNetwork, setSelectedNetwork] = useState<Service>();
	const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
	const [peakTimeforShare, {loading}] = useMutationToast(GENERATE_PEAK_TIME);
	const {data} = useQuery(GET_NETWORK_TIME_SLOTS, {
		variables: {
			networks: Object.keys(value),
		},
		skip: peakTime || !Object.keys(value).length,
	});
	const networkSlots = useMemo(
		() =>
			(data?.timeSlotsForNetwork || []).reduce((acc, slot) => {
				acc[slot.network] = dayjs(slot.scheduledFor);
				return acc;
			}, {}),
		[data?.timeSlotsForNetwork]
	);
	const dirtyCopy = useDirtyCopy(value);
	const toast = useToast();

	return useConfirmModal(() => {
		const {changes, discard, update, val} = dirtyCopy;
		const handleDateChange = (date: Dayjs) => {
			if (!selectedNetwork) return;
			const cur = val[selectedNetwork];
			if (!cur) return;
			update({
				[selectedNetwork]: {
					...cur,
					scheduledFor: date,
					peakTime: cur.peakTime && !!cur.scheduledFor?.isSame(date),
				},
			});
		};

		const handleEdit = (network: Service) => {
			setSelectedNetwork(network);
			setCalendarOpen(true);
		};

		const handlePeakTimeForAll = networks => {
			if (id) {
				peakTimeforShare({variables: {id, timezone: dayjs.tz.guess()}}).then(
					({data: {peakTimeforShare: peakTimes}}) => {
						const updates: typeof value = {};
						peakTimes.map(
							p =>
								(updates[p.network] = {
									...val[p.network],
									peakTime: true,
									scheduledFor: dayjs(p.scheduledFor),
								})
						);
						update(updates);
					}
				);
			} else {
				const updates: typeof value = {};
				(Object.entries(networks) as Array<[Service, NetworkShare]>).map(([service, v]) => {
					updates[service] = {...v, peakTime: true};
				});
				update(updates);
			}
		};

		const handleSchedule = close => {
			const values = (id ? changes : val) as ScheduleArg;
			if (values) {
				Object.keys(values).forEach(network => {
					if (!values[network].scheduledFor) {
						values[network].scheduledFor = networkSlots[network] ?? defaultDate;
					}
				});
			}

			if (values && Object.values(values).some(v => !v.peakTime && v.scheduledFor?.isBefore())) {
				toast({
					color: "red",
					text: "Please select a scheduled time that is not in the past!",
					icon: "warning",
				});
				return;
			}

			onConfirm(values, close);
			discard();
		};

		const handleNetworkPeakTime = async selectedNetwork => {
			if (id) {
				peakTimeforShare({
					variables: {id, networks: [selectedNetwork], timezone: dayjs.tz.guess()},
				}).then(({data}) => {
					const n = data?.peakTimeforShare?.[0];
					const scheduledFor = dayjs(n.scheduledFor);
					update({[n.network]: {...val[n.network], scheduledFor, peakTime: true}});
				});
			} else {
				update({
					[selectedNetwork]: {
						...val[selectedNetwork],
						scheduledFor: val[selectedNetwork]?.scheduledFor ?? defaultDate,
						peakTime: true,
					},
				});
			}

			setCalendarOpen(false);
		};

		return {
			title: "Set Schedule",
			body: (
				<div className={styles.headerContainer}>
					<Span2 className="space">Please schedule a time for your post.</Span2>
					{loadingParent && <Loading position="center" />}
					<div className={classNames(styles.list, "space")}>
						{(Object.entries(val) as Array<[Service, NetworkShare]>).sort().map(([network, share]) => (
							<div className={styles.service} key={network}>
								<Icon icon={network} />
								<Span>{accountNames[network]}</Span>
								{!share.peakTime ? (
									<>
										<Span>
											{dayjs(share.scheduledFor ?? networkSlots[network] ?? defaultDate).formatAs("numDate")}
										</Span>
										<Span>
											{dayjs(share.scheduledFor ?? networkSlots[network] ?? defaultDate).formatAs("time")}
										</Span>
									</>
								) : (
									<>
										<span></span>
										<Span color="pink">{share.peakTime ? "Peaktime™" : ""}</Span>
									</>
								)}
								<Button icon="edit" onClick={() => handleEdit(network)} invert border={false} />
							</div>
						))}
					</div>

					{!Object.values(val).every(v => v.peakTime) && peakTime && (
						<Button
							value="Use Peaktime™ for All"
							color="pink"
							invert
							loading={loading}
							onClick={() => handlePeakTimeForAll(val)}
						/>
					)}

					{selectedNetwork && (
						<PeakTimeCalendar
							isOpen={calendarOpen}
							peakTime={peakTime}
							value={val[selectedNetwork]?.scheduledFor ?? networkSlots[selectedNetwork] ?? defaultDate}
							onChange={handleDateChange}
							min={dayjs()}
							max={maxDate}
							onOpen={setCalendarOpen}
							onUsePeakTime={() => handleNetworkPeakTime(selectedNetwork)}
							loading={loading}
						/>
					)}
				</div>
			),
			onClose: discard,
			confirmText: "Schedule",
			onConfirm: handleSchedule,
			confirming,
		};
	}, [
		dirtyCopy,
		peakTime,
		loadingParent,
		selectedNetwork,
		calendarOpen,
		confirming,
		id,
		peakTimeforShare,
		onConfirm,
		toast,
		loading,
		maxDate,
		networkSlots,
	]);
}

export function useBulkScheduleModal<T>({value, onConfirm}: BulkScheduleModalProps): ModalHook<T> {
	const [date, setDate] = useState(value);

	return useConfirmModal(() => {
		const timeSpan = date !== undefined && (
			<div className={styles.timeContainer}>
				<Span className={[styles.dateTimeSpan, date && styles.dateTimeSet]}>
					{date?.formatAs() || "MM/DD/YYYY"}
				</Span>
				{date && <Icon icon="close" onClick={() => setDate(undefined)} />}
			</div>
		);

		const handleType = () => setDate(c => (c ? undefined : dayjs()));

		return {
			onOpen: () => setDate(value),
			title: "Set Schedule",
			body: (
				<div className={styles.headerContainer}>
					<InputRow position="between">
						<RadioGroup
							className={styles.radioGroup}
							options={options}
							value={date !== undefined}
							horizontal
							onChange={handleType}
						/>
					</InputRow>

					{date ? (
						<DateTimePicker isOpen labelComponent={timeSpan} value={date} onChange={setDate} min={dayjs()} />
					) : (
						<>
							<Span bold color="blue">
								Peaktime™ will select share times for this post
							</Span>
							<br />
							<br />
							<Span>
								Peaktime™ for scheduling refers to the optimal period when your content is most likely to be
								seen and engaged with by your audience, maximizing reach and potential interaction on social
								media platforms.
							</Span>
						</>
					)}
				</div>
			),
			confirmText: "Schedule",
			onConfirm: close => onConfirm(date, close),
		};
	}, [date, onConfirm, value]);
}
