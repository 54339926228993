import dayjs, {Dayjs} from "dayjs";
import {gql} from "@apollo/client";

export type {ShareEventAnalytics} from "./analytics";
export type {Category} from "./category";
export type {
	Collection,
	AnalyticsCollection,
	CollectionStatus,
	MovePostVars,
	Post,
	PostService,
	PostKeyword,
} from "./collection";
export type {Connection, Connections, ConnectionProfile} from "./connection";
export type {SuggestedShare} from "./feed";
export type {Group} from "./group";
export type {Metadata} from "./metadata";
export type {OpenGraph, OpenGraphs} from "./opengraph";
export type {Feature, Org, OrgSettings, SelfEnrollment} from "./org";
export type {RssFeed, RssFeedCategory} from "./rssFeed";
export type {SocialShuffleChat, ShuffledComment, ShuffledImage} from "./social-shuffle";
export type {Share, ShareEvent, ShareStatus} from "./share";
export type {SlackChannel} from "./slack";
export type {
	SalesforceIntegration,
	SalesforceLeadFields,
	SalesforceLeadField,
	SalesforceSyncResponse,
} from "./salesforce";
export type {
	HubspotIntegration,
	HubspotContactFields,
	HubspotContactField,
	HubspotSyncResponse,
} from "./hubspot";
export type {NewUser, Role, User} from "./user";

export {LOGIN, RESET_PASSWORD} from "./auth";
export {GET_CATEGORIES, CREATE_CATEGORY, CATEGORY_FRAGMENT, useCategories} from "./category";
export {
	ADD_CATEGORY_TO_COLLECTION,
	ADD_ORG_KEYWORD,
	ADD_POST,
	ADD_POST_DRAFT_COLLECTION,
	ADD_POST_FROM_RSS_FEED,
	ADD_POST_FROM_SUGGESTED_FEED,
	CREATE_COLLECTION,
	DELETE_POST,
	DELETE_COLLECTION,
	GET_COLLECTIONS_EXPORT,
	GET_COLLECTION,
	GET_COLLECTIONS,
	GET_ANALYTICS_COLLECTIONS,
	GET_COLLECTION_STATS,
	GET_POST_KEYWORDS,
	MOVE_POST,
	REMOVE_CATEGORY_FROM_COLLECTION,
	REORDER_CATEGORIES,
	RESEND_COLLECTION,
	SAVE_COLLECTION,
	UPDATE_POST,
	STOP_ALL_FUTURE_SHARES,
	getPostEmv,
	loadCollection,
	transformAnalyticsCollection,
} from "./collection";
export {ADD_COMPANY, COMPANY_FRAGMENT, CONNECT_COMPANY} from "./company";
export {useCompanies, useCompanyList} from "./company";
export {CONNECTIONS_FRAGMENT, GET_USER_CONNECTION_PAGES, GET_USER_CONNECTION_PROFILES} from "./connection";
export {GET_SUGGESTED_SHARES, HIDE_SUGGESTED_SHARE, inflateSuggestedShare} from "./feed";
export {CREATE_GROUP, GET_GROUPS, UPDATE_GROUP, useGroupList, useAllGroups, GROUP_FRAGMENT} from "./group";
export {inflateVideo, inflateImage, inflateImages, inflateVideos, inflateMediaUsers} from "./media";
export {OPENGRAPH_FRAGMENT, OPENGRAPH_FROM_URL} from "./opengraph";
export {
	REFRESH_CODE,
	SELF_ENROLLMENT,
	SELF_ENROLLMENT_FRAGMENT,
	UPDATE_ORG,
	UPDATE_SELF_ENROLLMENT,
	UPLOAD_LOGO,
	REMOVE_LOGO,
} from "./org";
export {
	ADD_CUSTOM_RSS_FEED,
	ADD_RSS_FEED,
	CUSTOM_RSS_FEED_FRAGMENT,
	DELETE_RSS_FEED,
	GET_JDSUPRA_CATEGORIES,
	GET_MANZAMA_CATEGORIES,
	GET_RSS_FEEDS,
	GET_USER_RSS_FEEDS,
	RSS_FEED_FRAGMENT,
	UPDATE_RSS_FEED,
	feedImage,
} from "./rssFeed";
export {
	CREATE_COMPANY_SHARE,
	CREATE_SHARE,
	GENERATE_PEAK_TIME,
	GET_COMPANY_SHARE_EVENTS,
	GET_SHARE,
	GET_USER_EVENTS,
	DELETE_SHARE_EVENT,
	DUPLICATE_COMPANY_SHARE,
	DUPLICATE_SHARE,
	GET_USER_SHARE_EVENTS,
	SHARE_EVENT_FRAGMENT,
	SHARE_FRAGMENT,
	SHARE_IMMEDIATELY,
	SHARE_WITH_SHARE_EVENT_FRAGMENT,
	SCHEDULE_QUEUE,
	SCHEDULE_SHARE_EVENT,
	UPDATE_SHARE,
	UPDATE_SHARE_EVENT,
	UPDATE_SHARE_NETWORK,
	loadShare,
	loadShareEvent,
} from "./share";
export {GET_SLACK_CHANNELS, SET_SLACK_CHANNELS} from "./slack";
export {
	GET_SALESFORCE_INTEGRATION,
	DISCONNECT_SALESFORCE,
	SET_SALESFORCE_INTEGRATION,
	SYNC_SALESFORCE_LEADS,
} from "./salesforce";
export {
	GET_HUBSPOT_INTEGRATION,
	DISCONNECT_HUBSPOT,
	SET_HUBSPOT_INTEGRATION,
	SYNC_HUBSPOT_CONTACTS,
} from "./hubspot";
export {REFRESH_SHUFFLE_AI} from "./social-shuffle";
export {
	ADD_USER,
	DEACTIVATE_USERS,
	DISCONNECT_USER_SOCIAL,
	GET_ME,
	GET_MY_STATS,
	GET_USERS,
	GET_MENTIONABLE_USERS,
	GET_EXPIRED_SOCIAL_MEDIA_USERS,
	REINVITE_USERS,
	REMIND_RECONNECT,
	SEND_FEEDBACK,
	UPDATE_ME,
	UPDATE_USER,
	UPLOAD_USERS,
	USER_FRAGMENT,
	loadUser,
	roleOptions,
	useMyUser,
	useUser,
	useUsers,
	useSimpleUsers,
	useUserList,
	usePendingUsers,
	useExpiredSocialMediaUsers,
} from "./user";
export {GET_VISITORS} from "./visitor";

export const services = ["linkedin", "twitter", "facebook", "instagram"] as const;
export type Service = typeof services[number];
export const postServices = [...services, "general"] as const;
export type PostServices = typeof postServices[number];
export const accountNames: Record<Service, string> = {
	facebook: "Facebook",
	linkedin: "LinkedIn",
	twitter: "X",
	instagram: "Instagram",
};
export const userAccountNames: Record<Exclude<Service, "instagram">, string> = {
	facebook: "Facebook",
	linkedin: "LinkedIn",
	twitter: "X",
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteTypename = <T extends Record<string, any>>(src: T): T => {
	const ret = {...src};
	delete ret.__typename;
	return ret;
};

export const loadDate = (date: string | null): Dayjs | undefined => (date ? dayjs(date) : undefined);

export const UPLOAD_IMAGE = gql`
	mutation UploadImage($file: Upload!) {
		uploadImage(file: $file)
	}
`;
