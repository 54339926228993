import {ReactElement, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLocalStorage, useSearchParam} from "react-use";
import {useQuery} from "@apollo/client";
import dayjs from "dayjs";
import {useLocation} from "react-router";

import {Button, DropdownButton, InputRow, Option, TabProps, Text} from "../../components/input";
import {
	GET_COMPANY_SHARE_EVENTS,
	GET_USER_EVENTS,
	GET_USER_SHARE_EVENTS,
	SCHEDULE_QUEUE,
	SCHEDULE_SHARE_EVENT,
	ShareEvent,
	ShareStatus,
	loadShareEvent,
	useMyUser,
} from "../../data";
import {defaultLoadingProps as loadingProps, usePaginatedQuery} from "../../paginated-query";
import {usePageScroll} from "../../layout";
import {ShareItem} from "./share-item";
import {ToggleGroup} from "../../components/toggle";
import {useDebounce} from "../../debounce";
import {useMutationToast, useToast} from "../../toast";
import {useCompanyPagesList} from "../../data/company";
import {TabsSelectSomeMenu} from "../../components/input/dropdown-button";

import styles from "./collection.module.scss";

const empty = (
	<div className={styles.empty}>
		<img src="/svgs/collection_blank.svg" alt="No Posts" />
	</div>
);
const renderItem = (shareEvent: ShareEvent) => <ShareItem key={shareEvent.id} shareEvent={shareEvent} />;

const options: Option<boolean>[] = [
	{value: false, label: "Company"},
	{value: true, label: "Personal"},
];

export interface PostsFilter {
	status: ShareStatus[];
	sort: "created" | "scheduled";
	accounts: number[];
}

const defaultFilter = (): PostsFilter => ({
	status: [],
	sort: "created",
	accounts: [],
});

export const Posts = (): ReactElement => {
	const [cookieValue, setPersonalShareEvents] = useLocalStorage("collections-posts-personal", false);
	const personalShareEvents = useMemo(() => cookieValue ?? false, [cookieValue]);
	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState(defaultFilter);
	const location = useLocation();
	const debouncedSearch = useDebounce(search, 300);
	const navigate = useNavigate();
	const user = useMyUser();
	const toast = useToast();

	const shareId = useSearchParam("queued_url_id");
	const queueId = useSearchParam("queue_id");
	const companyId = useSearchParam("company_user_id");
	const url = useSearchParam("url");

	const {companies, loading: loadingCompanies} = useCompanyPagesList();

	const [scheduleSharesByQueueUrlId] = useMutationToast(SCHEDULE_SHARE_EVENT);
	const [scheduleSharesByQueueId] = useMutationToast(SCHEDULE_QUEUE);

	useEffect(() => {
		if (location?.state?.filter) {
			setFilter(filter => ({
				...filter,
				...location?.state?.filter,
			}));
		}
	}, [location?.state?.filter]);

	useEffect(() => {
		const scheduleFunction = shareId ? scheduleSharesByQueueUrlId : queueId ? scheduleSharesByQueueId : null;
		if (url) {
			navigate("new", {state: {type: "personal", url}});
		}
		if (scheduleFunction) {
			setPersonalShareEvents(!companyId);
			toast({
				color: "blue",
				text: queueId
					? "Scheduling all items from your Collection for sharing"
					: "Scheduling your post for sharing",
				timeout: 2,
			});
			scheduleFunction({
				variables: {id: queueId ?? shareId, ...(companyId && {companyId}), timezone: dayjs.tz.guess()},
				onCompleted: response => {
					toast({
						color: "green",
						text: queueId ? "Your posts have been scheduled!" : "Your post has been scheduled!",
					});
					if (shareId)
						navigate(`/collections/posts/${response.scheduleSharesByQueueUrlId.id}`, {
							state: {expanded: true},
						});
				},
			});
		}
	}, [
		navigate,
		toast,
		shareId,
		companyId,
		url,
		setPersonalShareEvents,
		queueId,
		scheduleSharesByQueueUrlId,
		scheduleSharesByQueueId,
	]);

	const variables = useMemo(
		() => ({
			limit: 20,
			sort: filter.sort?.toUpperCase(),
			status: filter.status?.length ? filter.status?.map(s => s.toUpperCase()) : ["SCHEDULED", "FAILED"],
			ids: filter.accounts.length && !personalShareEvents ? filter.accounts : undefined,
			search: debouncedSearch,
		}),
		[filter, debouncedSearch, personalShareEvents]
	);

	const SCHEDULED_SHARE_EVENTS_QUERY =
		!personalShareEvents && user.role === "admin" ? GET_COMPANY_SHARE_EVENTS : GET_USER_SHARE_EVENTS;

	const {handleScroll, render, data} = usePaginatedQuery<ShareEvent>(SCHEDULED_SHARE_EVENTS_QUERY, {
		loadingProps,
		inflateItem: loadShareEvent,
		variables,
		renderItem,
		itemsClassName: styles.collectionList,
		empty,
	});
	usePageScroll(handleScroll);

	const toBeSentPosts = useMemo(
		() =>
			data
				?.filter(share => !share.sharedAt && dayjs(share?.scheduledFor).isBefore())
				?.map(share => share.id) ?? [],
		[data]
	);
	const {stopPolling, startPolling} = useQuery(GET_USER_EVENTS, {
		variables: {ids: toBeSentPosts},
		skip: !toBeSentPosts?.length,
	});

	useEffect(() => {
		if (toBeSentPosts.length === 0) return stopPolling();
		startPolling(5000);
	}, [startPolling, stopPolling, toBeSentPosts]);

	const postsTab = useMemo(() => {
		let t: TabProps<keyof Omit<PostsFilter, "sort">, string | number>[] = [
			{
				label: "Status",
				value: "status",
				options: [
					{value: "scheduled", label: "Scheduled"},
					{value: "failed", label: "Failed"},
					{value: "delivered", label: "Delivered"},
				],
			},
		];
		if (!personalShareEvents) {
			t = [
				{
					label: "Account",
					value: "accounts",
					loading: loadingCompanies,
					options: companies,
				},
				...t,
			];
		}
		return t;
	}, [companies, personalShareEvents, loadingCompanies]);

	const filterByButton: TabProps<keyof Omit<PostsFilter, "sort">, string | number>[] = useMemo(
		() =>
			user.role === "admin"
				? postsTab
				: [
						{
							label: "Status",
							value: "status",
							options: [
								{value: "scheduled", label: "Scheduled"},
								{value: "failed", label: "Failed"},
								{value: "delivered", label: "Delivered"},
							],
						},
				  ],
		[user, postsTab]
	);

	const total = useMemo(
		() =>
			Object.keys(filter).reduce(
				(acc, current) =>
					acc +
					(Array.isArray(filter[current]) && (current !== "accounts" || !personalShareEvents)
						? filter[current].length
						: 0),
				0
			),
		[filter, personalShareEvents]
	);

	const filterLabel = total > 0 ? `${total} filter(s) applied` : "Filter By";

	return (
		<>
			{user.role === "admin" && (
				<div className="space">
					<ToggleGroup options={options} onChange={setPersonalShareEvents} value={personalShareEvents} />
				</div>
			)}
			<InputRow position="between">
				<InputRow>
					<Button
						onClick={() => navigate("new", {state: {type: personalShareEvents ? "personal" : "company"}})}
						value="Create Post"
						icon="edit"
						className={styles.dropdownButton}
					/>
				</InputRow>
				<InputRow>
					<Text value={search} onChange={setSearch} placeholder="Search" icon="search" />
					<DropdownButton
						value="Sort By"
						arrow
						invert
						options={[
							{label: "Last Created", onClick: () => setFilter(c => ({...c, sort: "created"}))},
							{label: "Scheduled", onClick: () => setFilter(c => ({...c, sort: "scheduled"}))},
						]}
					/>
					<TabsSelectSomeMenu
						label={filterLabel}
						value={filter}
						tabs={filterByButton}
						onChange={v => setFilter(c => ({...c, ...v} as PostsFilter))}
					/>
				</InputRow>
			</InputRow>
			<div className="space">{render}</div>
		</>
	);
};
