import {ReactElement, useCallback, useRef} from "react";
import classnames from "classnames";
import html2canvas from "html2canvas";

import {P, Span2, Span4} from "../../../components/text";
import {useMutationToast} from "../../../toast";
import {Card} from "../../../components/card";
import {useMyUser} from "../../../data";
import {useDirtyCopy} from "../../../dirty-copy";
import {Button, Checkbox, InputRow, RadioGroup, Separator, SmallButton} from "../../../components/input";
import {ColorPicker} from "../../../components/input/color";
import {OrgSettings, UPDATE_ORG} from "../../../data/org";
import {EmailLogoUpload} from "./email-logo";

import styles from "./templates.module.scss";

const colorLabels = [
	{label: "Button Color", value: "queueButtonColor"},
	{label: "Link Color", value: "loginLinkColor"},
];
export const Templates = (): ReactElement => {
	const me = useMyUser();
	const shareButtonWrapperRef = useRef<HTMLDivElement>(null);
	const shareAllButtonWrapperRef = useRef<HTMLDivElement>(null);
	const [updateOrg, {loading}] = useMutationToast(UPDATE_ORG);
	const onUpdate = useCallback(
		async ({changes, discard}) => {
			const deletions: Record<string, boolean> = {};
			let fileShare: File | null = null;
			let fileShareAll: File | null = null;
			if (!changes) return;
			if (
				(changes.queueButtonColor || changes.loginLinkColor) &&
				shareButtonWrapperRef.current &&
				shareAllButtonWrapperRef.current
			) {
				const shareButton = await html2canvas(shareButtonWrapperRef.current, {scale: 2});
				const shareAllButton = await html2canvas(shareAllButtonWrapperRef.current, {scale: 2});
				const blobShare: Blob = await new Promise((resolve, reject) =>
					shareButton.toBlob(blob => (blob ? resolve(blob) : reject()), "image/png")
				);
				const blobShareAll: Blob = await new Promise((resolve, reject) =>
					shareAllButton.toBlob(blob => (blob ? resolve(blob) : reject()), "image/png")
				);
				if (blobShare && blobShareAll) {
					fileShare = new File([blobShare], "shareButtonImage.png", {type: "image/png"});
					fileShareAll = new File([blobShareAll], "shareAllButtonImage.png", {type: "image/png"});
				}
			}
			Object.keys(changes).forEach(field => {
				if (changes[field] === undefined) deletions[field] = true;
			});
			updateOrg({
				variables: {
					changes:
						fileShare && fileShareAll
							? {...changes, shareButtonImage: fileShare, shareAllButtonImage: fileShareAll}
							: changes,
					deletions,
				},
				onCompleted: discard,
			});
		},
		[updateOrg]
	);
	const {dirty, val, update, inputFunc, flush} = useDirtyCopy<
		Omit<OrgSettings, "emailLogo"> & {emailLogo?: File | string | undefined}
	>(me.org.settings, {debounce: 2000, onUpdate});

	return (
		<div className={styles.container}>
			<div className={styles.section}>
				<EmailLogoUpload value={val.emailLogo} update={update} loading={loading} />
			</div>
			<div className={styles.section}>
				<h4>Collections Emails</h4>
				<Span2 color="grey">
					Customize your email queue settings, tweak preferences, and preview email footer.
				</Span2>
				<div className={styles.emailPreview}>
					<Card column className={styles.emailPreviewContainer}>
						<div className={styles.headerContainer}>
							<img className={styles.logo} src={"/svgs/clearview-logo-full.svg"} alt="Company Logo" />
							<Span4 color="grey">[Title of queue]</Span4>
						</div>
						{val.queueCoBranding && (
							<div className={styles.byContainer}>
								<Span4 color="grey">By</Span4>
								<img className={styles.byLogo} src={"/svgs/clearview-logo-small.svg"} alt="Company Logo" />
							</div>
						)}
						<div className={styles.previewQueue}>
							<div className={styles.avatar}>
								<img className={styles.avatarImg} src="/person.png" />
								<Span4 color="grey">[Name of Admin]</Span4>
							</div>
							<div className={styles.content}>
								<P>[Admin Message will display here]</P>
								<Span4>Note: You’ll customize your message in each Queue and not here in the preview.</Span4>
							</div>
						</div>
					</Card>
					<div className={styles.cardSplit}>
						<Card>
							<RadioGroup
								horizontal
								label="Send As:"
								options={[
									{value: false, label: "Admin"},
									{value: true, label: "Company"},
								]}
								{...inputFunc("suppressAdminSpoof")}
							/>
							<Checkbox
								label="Include Daily Social Media Tip"
								value={!val.hideTips}
								onChange={newValue => update({hideTips: !newValue})}
							/>
							<Checkbox
								label="Enable Co-Branding"
								value={!val.queueCoBranding}
								onChange={newValue => update({queueCoBranding: !newValue})}
							/>
						</Card>
					</div>
				</div>
			</div>
			<div className={styles.section}>
				<h4>Articles Styling</h4>
				<Span2 color="grey">
					Customize your email queue settings, tweak preferences, and preview email footer.
				</Span2>
				<SmallButton
					color="blue"
					value="Use default"
					onClick={() => update({loginLinkColor: "#2AB9FB", queueButtonColor: "#e72b81"})}
					border={false}
					invert
				/>

				<div className={styles.emailPreview}>
					<Card column className={styles.emailPreviewContainer}>
						<InputRow position="center">
							<div
								ref={shareAllButtonWrapperRef}
								style={{borderColor: val.queueButtonColor, color: val.queueButtonColor}}
								className={classnames(styles.shareAllButton, styles.border)}
							>
								<span className={styles.shareAllText}>Share All</span>
							</div>
						</InputRow>
						<Separator horizontal />
						<div className={styles.queueContainer}>
							<div className={styles.queue}>
								<div className={styles.queueTitle}>
									<img className={styles.queueImg} src="/handshake.png" alt="Company Logo" />
									<h6 style={{color: val.loginLinkColor}} className={styles.queueText}>
										The Small Change That Creates Massive Results in Your Life Starts With this One Small
										Step.
									</h6>
								</div>
								<Span4 className={styles.queueDescription}>
									The Small Change That Creates Massive Results In Your Life Starts with this One Small Step.
								</Span4>
							</div>

							<InputRow position="right">
								<div
									ref={shareButtonWrapperRef}
									style={{borderColor: val.queueButtonColor, color: val.queueButtonColor}}
									className={classnames(styles.shareButton, styles.border)}
								>
									<span className={styles.shareText}>Share</span>
								</div>
								<div className={styles.shareButton}>
									<span style={{color: val.loginLinkColor}} className={styles.shareText}>
										Share via email
									</span>
								</div>
							</InputRow>
						</div>
						<Separator horizontal />
					</Card>
					<div className={styles.cardSplit}>
						<ColorPicker
							value={{
								queueButtonColor: val.queueButtonColor ?? "#E72B81",
								loginLinkColor: val.loginLinkColor ?? "#2AB9FB",
							}}
							colors={colorLabels}
							onChange={update}
						/>
					</div>
				</div>
			</div>
			<Separator horizontal />
			<Button onClick={flush} loading={loading} disabled={!dirty} value="Save Changes" />
		</div>
	);
};
