import {ReactElement, useCallback} from "react";
import classnames from "classnames";

import {P2, Span1, Span2, Span4} from "../../../components/text";
import {Button, File, InputRow} from "../../../components/input";
import {useModal} from "../../../modals";
import {UpdateFunc} from "../../../dirty-copy";
import {OrgSettings} from "../../../data";

import styles from "./logo.module.scss";

interface EmailUploadProps {
	value?: string | File | undefined;
	update: UpdateFunc<Omit<OrgSettings, "emailLogo"> & {emailLogo?: File | string | undefined}>;
	loading: boolean;
}

export const EmailLogoUpload = ({value, update, loading}: EmailUploadProps): ReactElement => {
	const handleConfirm = useCallback(
		([file]: File[], resetState: () => void) => {
			update({emailLogo: file});
			resetState();
		},
		[update]
	);

	const removeLogoModal = useModal(
		({close}) => ({
			size: "small",
			body: (
				<div className={styles.removeLogoModal}>
					<InputRow position="center">
						<P2 bold>You’re about remove the logo</P2>
					</InputRow>
					<InputRow position="center">
						<P2>Please confirm below.</P2>
					</InputRow>
				</div>
			),
			footer: (
				<InputRow position="center">
					<Button
						value="Remove Current Email Logo"
						loading={loading}
						onClick={() => {
							update({emailLogo: undefined});
							close();
						}}
					/>
				</InputRow>
			),
		}),
		[loading, update]
	);

	return (
		<div>
			<Span1 bold>Logos</Span1>
			<div className={classnames(styles.container, "space")}>
				<Span2 color="grey">
					Personalize your email templates with your company logo. It will provide a more professional look to
					your email templates.
				</Span2>
			</div>
			<div className={classnames(styles.container, "space")}>
				<File
					what="image"
					accept={{"image/png": [".png"], "image/jpeg": [".jpg", ".jpeg"]}}
					onFile={handleConfirm}
					loading={loading}
				/>
				<div className={styles.imageContainer}>
					<img
						src={!value ? "/no_clogo.svg" : typeof value === "string" ? value : URL.createObjectURL(value)}
						alt="Company Logo"
						height={200}
					/>
					<Span4 color="grey">Emails Logo</Span4>
					{value && (
						<Span2 bold onClick={removeLogoModal.open}>
							Remove Current Logo
						</Span2>
					)}
				</div>
			</div>
		</div>
	);
};
