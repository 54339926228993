import {gql} from "@apollo/client";

import {PostService} from "./collection";

export interface SocialShuffleChat {
	message: string;
	prompt: string;
	id: string;
	service: PostService;
}
export interface ShuffledComment {
	id: string;
	isAIGenerated: boolean;
	comment: string;
}
export interface ShuffledImage {
	id: string;
	image: string;
}

const AI_CHAT_FRAGMENT = gql`
	fragment AIChatFields on SocialShuffleChat {
		message
		prompt
		id
		service
	}
`;

export const SHUFFLED_COMMENT_FRAGMENT = gql`
	fragment ShuffledCommentFields on ShuffledComment {
		id
		queuedUrlId
		isAIGenerated
		comment
	}
`;

export const GET_SHUFFLED_COMMENTS = gql`
	query ShuffledComments($queuedUrlId: PositiveInt!) {
		shuffledComments(queuedUrlId: $queuedUrlId) {
			...ShuffledCommentFields
		}
	}
	${SHUFFLED_COMMENT_FRAGMENT}
`;

export const REFRESH_SHUFFLE_AI = gql`
	mutation SocialShuffleChat($aiChat: SocialShuffleChatInput!) {
		refreshSocialShuffle(aiChat: $aiChat) {
			...AIChatFields
		}
	}
	${AI_CHAT_FRAGMENT}
`;

export const CREATE_SHUFFLED_COMMENT = gql`
	mutation CreateShuffledComment($queuedUrlId: PositiveInt!, $isAIGenerated: Boolean, $comment: String!) {
		createShuffledComment(queuedUrlId: $queuedUrlId, isAIGenerated: $isAIGenerated, comment: $comment) {
			...ShuffledCommentFields
		}
	}
	${SHUFFLED_COMMENT_FRAGMENT}
`;

export const DELETE_SHUFFLED_COMMENTS = gql`
	mutation DeleteShuffledComments($queuedUrlId: PositiveInt!) {
		deleteShuffledComments(queuedUrlId: $queuedUrlId) {
			queuedUrlId
		}
	}
`;

export const DELETE_SHUFFLED_COMMENT = gql`
	mutation DeleteShuffledComment($id: PositiveInt!) {
		deleteShuffledComment(id: $id) {
			...ShuffledCommentFields
		}
	}
	${SHUFFLED_COMMENT_FRAGMENT}
`;

export const UPDATE_SHUFFLED_COMMENT = gql`
	mutation UpdateShuffledComment($id: PositiveInt!, $isAIGenerated: Boolean, $comment: String) {
		updateShuffledComment(id: $id, isAIGenerated: $isAIGenerated, comment: $comment) {
			...ShuffledCommentFields
		}
	}
	${SHUFFLED_COMMENT_FRAGMENT}
`;
