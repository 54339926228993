import React, {FC, KeyboardEvent, useCallback, useState} from "react";
import EmojiPicker from "emoji-picker-react";

import {Input, InputComponent, useId} from "./index";
import {AlerterWidget} from "../alerter";
import {Icon} from "../images";
import {HoverTooltip} from "../tooltip";

import styles from "./input.module.scss";
export interface EmojiProps extends InputComponent<string> {
	searchDisabled?: boolean;
}
export const EmojiPickerInput: FC<EmojiProps> = ({
	value,
	onChange,
	disabled,
	id: maybeId,
	searchDisabled,
	...props
}) => {
	const id = useId(maybeId);
	const [isOpen, setIsOpen] = useState(false);
	const handleOpen = c => !disabled && setIsOpen(c);

	const handleKeyDown = useCallback(
		(e: KeyboardEvent<HTMLDivElement>) => {
			if (disabled) return;

			switch (e.key) {
				case "Enter":
				case " ":
					setIsOpen(true);
					break;
				case "Escape":
					setIsOpen(false);
					break;
				default:
					return;
			}
			e.stopPropagation();
			e.preventDefault();
		},
		[disabled]
	);

	return (
		<Input baseClass="emoji" id={id} disabled={disabled} {...props}>
			<div tabIndex={disabled ? -1 : 0} onKeyDown={handleKeyDown}>
				<AlerterWidget
					onOpen={handleOpen}
					isOpen={isOpen}
					labelComponent={
						<HoverTooltip text={"Emoji Picker"} positions={["top"]}>
							<Icon
								className={[styles.emoji, disabled && styles.disabled]}
								icon="smile"
								height={20}
								width={20}
							/>
						</HoverTooltip>
					}
				>
					<EmojiPicker
						lazyLoadEmojis={true}
						searchDisabled={searchDisabled}
						previewConfig={{showPreview: false}}
						onEmojiClick={({emoji}) => onChange(`${value}${emoji}`)}
					/>
				</AlerterWidget>
			</div>
		</Input>
	);
};
