import {ReactElement, ReactNode, useCallback, useEffect, useState} from "react";
import dayjs, {Dayjs} from "dayjs";

import {AlerterWidget} from "../../alerter";
import {DatePicker, HourPicker, MonthNavigator} from ".";
import {Button, ButtonProps, Input, InputComponent, InputRow, SmallButton, useId} from "..";
import {DropdownCallback, useDropdown} from "../dropdown";
import {Span3, Span4} from "../../text";

import styles from "./date-time-picker.module.scss";

export interface DateTimePickerComponent extends InputComponent<Dayjs> {
	min: Dayjs;
	max?: Dayjs;
	isOpen?: boolean;
	labelComponent?: ReactNode;
	peakTime?: boolean;
}

export interface PeakTimeCalendarComponent extends Omit<DateTimePickerComponent, "labelComponent"> {
	loading: boolean;
	onUsePeakTime: () => void;
	onOpen: (v: boolean) => void;
}

export const DateTimePicker = ({
	id: maybeId,
	value,
	min,
	max,
	isOpen = false,
	labelComponent,
	onChange,
	...props
}: DateTimePickerComponent): ReactElement => {
	const id = useId(maybeId);
	const [isInternalOpen, setIsInternalOpen] = useState(isOpen);
	const [tempValue, setTempValue] = useState(value);
	const [displayMonth, setDisplayMonth] = useState(value.startOf("month"));

	const handleCancel = () => setIsInternalOpen(false);

	const handleSave = () => {
		onChange(tempValue);
		setIsInternalOpen(false);
	};

	const handleOnOpen = v => {
		setIsInternalOpen(v);
		setTempValue(value);
		setDisplayMonth(value.startOf("month"));
	};

	return (
		<Input id={id} {...props}>
			<AlerterWidget labelComponent={labelComponent} isOpen={isInternalOpen} onOpen={handleOnOpen}>
				<div className={styles.datepicker}>
					<MonthNavigator value={displayMonth} onChange={setDisplayMonth} max={max} min={min} />
					<DatePicker
						value={tempValue}
						displayMonth={displayMonth}
						onChange={setTempValue}
						min={min}
						max={max}
					/>
					<HourPicker value={tempValue} onChange={setTempValue} />

					{max && tempValue >= max && (
						<>
							<Span4 className="space" color="red">
								Please select a time before {max.format("hh:mm a")} on {max.format("MM/DD/YYYY")}
							</Span4>
						</>
					)}
					<InputRow className={styles.divider} position="between">
						<Button value="Cancel" invert onClick={handleCancel} />
						<Button value="Continue" disabled={max ? tempValue >= max : false} onClick={handleSave} />
					</InputRow>
				</div>
			</AlerterWidget>
		</Input>
	);
};

export interface CalendarButtonProps extends Omit<ButtonProps, "onClick" | "value"> {
	value?: Dayjs;
	emptyValue?: string;
	onChange: (newValue: Dayjs) => void;
	min?: Dayjs;
	size?: "small" | "medium" | "large";
}

export const CalendarButton = ({
	value,
	emptyValue,
	min,
	onChange,
	size: buttonSize = "medium",
	...props
}: CalendarButtonProps): ReactElement => {
	const [tempValue, setTempValue] = useState(() => value ?? dayjs());
	const [displayMonth, setDisplayMonth] = useState(() => tempValue.startOf("month"));
	const handleUnset = useCallback(() => {
		setTempValue(() => value ?? dayjs());
		setDisplayMonth(() => (value ?? dayjs()).startOf("month"));
	}, [value]);

	useEffect(() => setTempValue(value ?? dayjs()), [value]);

	const B = buttonSize === "small" ? SmallButton : Button;

	const popup = useCallback(
		({close}: DropdownCallback) => {
			const handleSave = () => {
				onChange(tempValue);
				close();
			};
			return (
				<>
					<MonthNavigator value={displayMonth} onChange={setDisplayMonth} />
					<DatePicker value={tempValue} displayMonth={displayMonth} onChange={setTempValue} min={min} />
					<HourPicker value={tempValue} onChange={setTempValue} />
					<InputRow className={styles.divider} position="between">
						<Button value="Cancel" invert onClick={close} />
						<Button value="Continue" onClick={handleSave} />
					</InputRow>
				</>
			);
		},
		[displayMonth, min, onChange, tempValue]
	);

	const {portal, reference, toggle} = useDropdown({
		popup,
		portalClassName: styles.dateButton,
		onClose: handleUnset,
		onMouseOut: handleUnset,
	});

	return (
		<>
			<B
				onClick={toggle}
				ref={reference}
				value={value?.formatAs() ?? emptyValue ?? ""}
				large={buttonSize === "large"}
				{...props}
			/>
			{portal}
		</>
	);
};

export const PeakTimeCalendar = ({
	id: maybeId,
	value,
	min,
	max,
	isOpen = false,
	onChange,
	onUsePeakTime,
	onOpen,
	peakTime = true,
	loading,
	...props
}: PeakTimeCalendarComponent): ReactElement => (
	<Input id={useId(maybeId)} {...props}>
		<AlerterWidget isOpen={isOpen} onOpen={onOpen} className={styles.peakTime}>
			<div className={styles.datepicker}>
				<MonthNavigator value={value.startOf("month")} onChange={onChange} max={max} min={min} />
				<DatePicker
					value={value}
					displayMonth={value.startOf("month")}
					onChange={onChange}
					min={min}
					max={max}
				/>
				<InputRow className={styles.divider} position="between">
					<HourPicker value={value} onChange={onChange} min={min} max={max} className={styles.peakHour} />
					{peakTime && (
						<Button value="Use Peaktime™" color="pink" invert onClick={onUsePeakTime} loading={loading} />
					)}
				</InputRow>
				{peakTime && (
					<Span3>
						Our PeakTime™ smart algorithm will optimize your posts and shares by scheduling best times to
						receive the most attention.{" "}
						<a
							href="https://cvssupport.wpenginepowered.com/articles/what-is-peaktime"
							target="_blank"
							rel="noreferrer"
						>
							Learn More
						</a>
					</Span3>
				)}
				<InputRow className={styles.divider} position="right">
					<Button
						value="Continue"
						onClick={() => {
							onOpen(false);
						}}
					/>
				</InputRow>
			</div>
		</AlerterWidget>
	</Input>
);
