import {ReactElement, useState} from "react";
import {HexColorPicker} from "react-colorful";

import {Button, Input, InputComponent, useId, Text} from ".";
import {Card} from "../card";
import {Span2} from "../text";

import styles from "./input.module.scss";

export type HexColorString = `#${string}`;

export interface ColorPickerProps<K extends string> extends InputComponent<Record<K, HexColorString>> {
	colors: {label: string; value: K}[];
}

export const ColorPicker = <K extends string>({
	id: maybeId,
	value,
	colors,
	onChange,
	...props
}: ColorPickerProps<K>): ReactElement => {
	const id = useId(maybeId);

	const [selectedColor, setSelectedColor] = useState<K>(colors[0].value);
	const [open, setOpen] = useState(false);

	const handleColorChange = (selected: K, newColor: string) => {
		setSelectedColor(selected);
		onChange({
			...value,
			[selected]: newColor as HexColorString,
		});
	};

	return (
		<Input id={id} {...props}>
			<Card className={styles.colorPicker}>
				<HexColorPicker color={value[selectedColor]} onChange={v => handleColorChange(selectedColor, v)} />
				<Button
					value="HEX"
					onClick={() => setOpen(c => !c)}
					arrow={open ? "up" : "down"}
					invert
					border={false}
				/>
				{open && (
					<>
						<Text
							value={value[selectedColor]?.toUpperCase()}
							onChange={value => handleColorChange(selectedColor, value)}
						/>
						{colors.map(c => (
							<div key={c.value} className={styles.color}>
								<div
									style={{
										background: value[c.value],
									}}
									onClick={() => setSelectedColor(c.value)}
									className={styles.colorButton}
								></div>
								<div className={styles.colorInput}>
									<Span2>{c.label}</Span2>
									<Text
										value={value[c.value]?.toUpperCase()}
										onChange={value => handleColorChange(c.value, value)}
										onFocus={() => setSelectedColor(c.value)}
									/>
								</div>
							</div>
						))}
					</>
				)}
			</Card>
		</Input>
	);
};
