import {useCallback, useMemo} from "react";
import {useNavigate, useParams} from "react-router-dom";
import classNames from "classnames";
import {useLazyQuery, useQuery} from "@apollo/client";
import dayjs from "dayjs";

import {CONTEST, CONTEST_LEADERBOARD, DELETE_CONTEST, EXPORT_CONTEST_DATA} from "../../data/contest";
import {Span, Span1} from "../../components/text";
import {AddContestModal, kpiDrivers} from "./contests";
import {Button, InputRow, Separator} from "../../components/input";
import {Modal, useModal} from "../../modals/new";
import {useMutationToast} from "../../toast";
import {useConfirmModal} from "../../modals";
import {UserAvatar} from "../../components/user-avatar";
import {Icon} from "../../components/images";
import {usePaginatedQuery} from "../../paginated-query";
import {usePageScroll} from "../../layout";
import {useMyUser} from "../../data";
import {RewardsModal} from "./rewardsModal";
import {downloadCsv} from "../../utils/csv";

import styles from "./contest.module.scss";

const kpiDriverKeys = {
	emv: "emv",
	shared_articles: "shares",
	clicks: "clicks",
	smart_score: "smartScore",
};

const Contest = () => {
	const navigate = useNavigate();
	const me = useMyUser();
	const isAdmin = useMemo(() => me.role === "admin", [me.role]);
	const {id: stringId} = useParams();
	const {data, loading} = useQuery(CONTEST, {
		variables: {id: stringId},
		skip: !stringId,
	});
	const [runQuery, {loading: loadingExport}] = useLazyQuery(EXPORT_CONTEST_DATA, {
		variables: {
			contestId: stringId,
		},
		fetchPolicy: "no-cache",
	});
	const {modal: rewardsModal, open: openRewardsModal, close: closeRewardsModal} = useModal({});
	const empty = useMemo(
		() => (
			<div className={styles.empty}>
				<h3>No users have shared in the selected period.</h3>
			</div>
		),
		[]
	);
	const metric = data?.contest?.kpiDriver;
	const name = data?.contest?.name;
	const prize = data?.contest?.prize;
	const milestone = data?.contest?.milestone;
	const winners = data?.contest?.winners;
	const complete = data?.contest?.complete;

	const LeaderboardItem = useCallback(
		({leaderboard, user, userTotalExperience}, index) => {
			const qualified = milestone <= leaderboard[kpiDriverKeys[metric]];
			const winner = qualified && index < winners;
			return (
				<>
					<div key={user.id} className={styles.leaderboardItem}>
						<div className={classNames(styles.rank, qualified && styles.qualified)}>
							<h3>{leaderboard.rank}</h3>
							<Span>Rank</Span>
						</div>
						<div className={styles.user}>
							<div className={styles.avatar}>
								<UserAvatar experience={userTotalExperience} name userId={user.id} size="extraLarge" />
							</div>
						</div>
						<div className={styles.rightSide}>
							<div className={styles.metrics}>
								{[...kpiDrivers].reverse().map(({value, label}) => (
									<div
										key={`${value}-${user.id}`}
										className={classNames(
											styles.metric,
											metric === value && styles.active,
											metric === value && qualified && styles.complete
										)}
									>
										{metric === value && (
											<Icon
												className={classNames(styles.star, qualified ? styles.pink : styles.grey)}
												viewBox="0 0 17 15"
												width={16}
												height={16}
												icon="star"
											/>
										)}
										<Span1>
											{leaderboard[kpiDriverKeys[value]]}
											{metric === value ? `/${milestone}` : ""}
										</Span1>
										<Span className={styles.metricLabel}>
											{label}
											{label === "EMV" && (
												<Icon
													color="blue"
													icon="information"
													onClick={() =>
														window.open(
															"https://cvssupport.wpenginepowered.com/article/how-does-earned-media-value-work",
															"_blank"
														)
													}
												/>
											)}
										</Span>
									</div>
								))}
							</div>
							<Separator />
							<div className={styles.reward}>
								<Span1 color={winner ? "pink" : "black"}> {!winner ? "—" : `$${prize} coupon`} </Span1>
								<Span>Reward</Span>
							</div>
						</div>
					</div>
				</>
			);
		},
		[metric, milestone, prize, winners]
	);

	const variables = useMemo(
		() => ({
			contestId: stringId,
		}),
		[stringId]
	);

	const {handleScroll, render, loading: loadingLeaderboard} = usePaginatedQuery(CONTEST_LEADERBOARD, {
		variables: variables,
		renderItem: LeaderboardItem,
		empty,
	});
	usePageScroll(handleScroll);

	const [deleteContest, {loading: loadingDelete}] = useMutationToast(DELETE_CONTEST);

	const onExport = useCallback(() => {
		runQuery().then(({data}) => {
			const columns = data.exportContestData.columns;
			const csvData = data.exportContestData.data;
			downloadCsv(csvData, columns, `${name}.csv`);
		});
	}, [runQuery, name]);

	const contest = useMemo(
		() =>
			data?.contest
				? {
						...data?.contest,
						startDate: dayjs(data?.contest.startDate),
						endDate: dayjs(data?.contest.endDate),
				  }
				: null,
		[data?.contest]
	);
	const {modal, open, close} = useModal({});

	const confirmDeleteModal = useConfirmModal(
		() => ({
			title: `Delete Contest`,
			body: `
				Are you sure you want to delete the ${contest?.name} contest?
			`,

			confirmColor: "blue",
			confirmText: "Delete",
			onConfirm: () =>
				deleteContest({
					variables: {id: contest.id},
					onCompleted: () => {
						navigate("/contests");
					},
				}),
			confirming: loadingDelete,
		}),
		[deleteContest, loadingDelete, contest, navigate]
	);

	const isFinnished = dayjs().isAfter(contest?.endDate);
	const contestants = contest?.totalContestants ?? 0;

	return (
		<div className={styles.contest}>
			<Modal modal={modal} title={`Edit Contest`}>
				<AddContestModal contest={contest} close={close} />
			</Modal>
			<div className={styles.header}>
				<div className={styles.leftHeader}>
					<h3>{contest?.name}</h3>
				</div>
				<div className={styles.rightHeader}>
					<InputRow>
						{isAdmin && !complete && !isFinnished && !loading && (
							<Button value="Edit" invert onClick={open} icon="edit" iconSize="medium" />
						)}
						{!loading &&
							isAdmin &&
							(isFinnished && !complete ? (
								<>
									<Button value="Distribute Rewards" onClick={openRewardsModal} disabled={!contestants} />
									<RewardsModal close={closeRewardsModal} modal={rewardsModal} />
								</>
							) : (
								<>
									<Button
										value="Delete"
										invert
										onClick={confirmDeleteModal.open}
										icon="delete"
										iconSize="medium"
									/>
								</>
							))}
						<Button
							value="Export"
							loading={loadingExport}
							onClick={onExport}
							disabled={!name}
							icon="export"
							iconSize="medium"
						/>
					</InputRow>
				</div>
			</div>
			<div className={styles.content}>
				{!loadingLeaderboard && !loading && (
					<div className={styles.contestInfo}>
						<div className={styles.data}>
							{!complete && (
								<>
									<div>
										<Span>
											Status:{" "}
											<Span color={!isFinnished ? "pink" : "blue"}>
												{isFinnished
													? "Gifts Ready!"
													: contest?.startDate?.isSameOrBefore(dayjs())
													? "In Progress"
													: "Upcoming"}
											</Span>
										</Span>
									</div>
									<div className={styles.bullet} />
								</>
							)}
							<Span>
								{contest?.startDate?.format("MMM DD, YYYY")} - {contest?.endDate?.format("MMM DD, YYYY")}
							</Span>
							<div className={styles.bullet} />
							<Span>
								{contest?.milestone} {kpiDrivers.find(({value}) => value === contest?.kpiDriver)?.label}{" "}
								required
							</Span>
							<div className={styles.bullet} />
							<Span>
								{isFinnished ? Math.min(contestants, winners) : contestants} users{" "}
								{isFinnished ? "won" : "qualifying"}
							</Span>
						</div>
					</div>
				)}
				<div>
					<div className={styles.leaderboard}>{render}</div>
				</div>
			</div>
		</div>
	);
};

export default Contest;
