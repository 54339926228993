import {useCallback, useState} from "react";
import classNames from "classnames";
import {Link, useNavigate} from "react-router-dom";

import {CREATE_COLLECTION, useMyUser} from "../data";
import {useMutationToast, useToast} from "../toast";
import {Button, InputRow, Separator, Text} from "../components/input";
import {Icon} from "../components/images";
import {Span2, Span4} from "../components/text";
import {useDropdown} from "../components/input/dropdown";
import {Modal, useModal} from "../modals/new";
import {Loading} from "../components/loading";
import {SUGGEST_URL} from "../data/rssFeed";
import {HoverTooltip} from "../components/tooltip";

import styles from "./quick-add.module.scss";

function isValidHttpUrl(string) {
	let url;

	try {
		url = new URL(string);
	} catch (_) {
		return false;
	}

	return url.protocol === "http:" || url.protocol === "https:";
}

export const QuickAdd = () => {
	const [text, setText] = useState<string>("");
	const [error, setError] = useState<boolean>(false);
	const toast = useToast();
	const navigate = useNavigate();
	const me = useMyUser();
	const {modal, open: openModal, close} = useModal({zIndex: 1001});

	const [createCollection, {loading}] = useMutationToast(CREATE_COLLECTION, {
		onCompleted: ({createCollection: {id}}) => navigate(`/collections/${id}`),
	});
	const [suggestUrl, {loading: loadingSuggest}] = useMutationToast(SUGGEST_URL, {
		onCompleted: () => {
			setMessage("");
			setText("");
			close();
			toast({color: "green", text: "Article suggested to admins."});
		},
	});

	const onShare = useCallback(() => {
		navigate("/collections/posts/new", {state: {type: "personal", url: text}});
		setText("");
	}, [navigate, text]);

	const isAdmin = me.role.includes("admin");

	const validLink = text.startsWith("http") ? text : `https://${text}`;

	const popup = useCallback(
		({close}) => (
			<div className={styles.quickAddContainer}>
				<div>
					<Text value={text} onChange={setText} placeholder="Paste an article link to share" />
					{text && error && <Span4 color="red">Please enter a valid URL</Span4>}
				</div>
				{text ? (
					<InputRow>
						<Button
							value="Share"
							onClick={() => {
								if (!isValidHttpUrl(text)) {
									setError(true);
									return;
								} else {
									setError(false);
								}
								close();
								onShare();
							}}
						/>
						<Button
							value="Suggest to Admins"
							onClick={() => {
								if (!isValidHttpUrl(text)) {
									setError(true);
									return;
								} else {
									setError(false);
								}
								close();
								openModal();
							}}
						/>
					</InputRow>
				) : (
					<div className={styles.links} onClick={close}>
						{isAdmin && (
							<Link to="/collections/posts/new" state={{type: "company"}} className={styles.quickMenuLink}>
								<Icon icon="office" color="grey" />
								<Span2 bold>New Company Post</Span2>
							</Link>
						)}
						<Link to="/collections/posts/new" state={{type: "personal"}} className={styles.quickMenuLink}>
							<Icon icon="posts" color="grey" />
							<Span2 bold>New {isAdmin && "Personal"} Post</Span2>
						</Link>
						{isAdmin && (
							<Span2
								onClick={createCollection}
								className={classNames(styles.quickMenuLink, loading && styles.disabled)}
							>
								{loading && <Loading position="absolute" size="small" />}
								<Icon icon="collections" color="grey" />
								<Span2 bold>New Collection</Span2>
							</Span2>
						)}
						{isAdmin && (
							<Link to="/contests" state={{new: true}} className={styles.quickMenuLink}>
								<Icon icon="trophy" color="grey" />
								<Span2 bold>New Contest</Span2>
							</Link>
						)}
					</div>
				)}
			</div>
		),
		[text, createCollection, loading, openModal, onShare, error, isAdmin]
	);

	const {open, portal, reference} = useDropdown({
		popup,
		portalClassName: styles.menu,
	});
	const [message, setMessage] = useState<string>("");
	const suggest = useCallback(() => {
		suggestUrl({
			variables: {url: validLink, comment: message},
		});
	}, [suggestUrl, message, validLink]);

	return (
		<div className={styles.quickAdd}>
			{portal}
			<HoverTooltip text="Quick add">
				<Button ref={reference} onClick={open} icon="edit" />
			</HoverTooltip>
			<Modal modal={modal} title="Suggest Article to Admins">
				<div>
					Article link:
					<br />
					<Span2>
						<a href={validLink} target="_blank" rel="noreferrer">
							{validLink}
						</a>
					</Span2>
				</div>
				<br />
				<Text
					label="Add a message to your admins"
					value={message}
					onChange={setMessage}
					type="textarea"
					autoFocus
				/>
				<Separator horizontal />
				<Button onClick={suggest} loading={loadingSuggest} value="Suggest" />
			</Modal>
		</div>
	);
};
