import {ReactElement, useCallback, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";

import {getShareStatusBadge, statusBadges} from ".";
import {EmptyComponent} from "../../types";
import {DropdownButton, InputRow} from "../../components/input";
import {
	Service,
	ShareEvent,
	useCompanyList,
	DUPLICATE_COMPANY_SHARE,
	DUPLICATE_SHARE,
	DELETE_SHARE_EVENT,
	UPDATE_SHARE_EVENT,
	useUser,
} from "../../data";
import {useMutationToast, useToast} from "../../toast";
import {Badge} from "../../components/badge";
import {Card} from "../../components/card";
import {Span4, Span} from "../../components/text";
import {Social} from "../../components/social";
import {useConfirmDeleteModal} from "../../modals";

import styles from "./collection-item.module.scss";

export interface ShareEventProps extends EmptyComponent {
	shareEvent: ShareEvent;
}

export const ShareItem = ({className, shareEvent}: ShareEventProps): ReactElement => {
	const user = useUser();

	const opengraph =
		(shareEvent.share.opengraphs[shareEvent.network] ?? shareEvent.share.opengraphs.general) || {};
	const navigate = useNavigate();
	const toast = useToast();
	const companies = useCompanyList();
	const edit = useCallback(() => navigate(`/collections/posts/${shareEvent.share.id}`), [
		navigate,
		shareEvent.share.id,
	]);
	const company = companies.find(c => c.value === shareEvent.share.companyId)?.label;
	const date = shareEvent.sharedAt ?? shareEvent.scheduledFor;
	const [updateShareEvent, {loading: updating}] = useMutationToast(UPDATE_SHARE_EVENT);

	const [duplicateShare] = useMutationToast(
		shareEvent?.share?.companyId ? DUPLICATE_COMPANY_SHARE : DUPLICATE_SHARE,
		{
			variables: {
				id: shareEvent.id,
				...(shareEvent?.share?.companyId ? {companyId: shareEvent.share.companyId} : {}),
			},
		}
	);

	const [deleteShareEvent, {loading: deleting}] = useMutationToast(DELETE_SHARE_EVENT, {
		variables: {id: shareEvent.id},
	});

	const title = (shareEvent.share.url ? opengraph?.title : opengraph?.comment) || "";

	const handleDelete = useCallback(close => deleteShareEvent().then(close), [deleteShareEvent]);
	const deleteModal = useConfirmDeleteModal({what: title, onDelete: handleDelete, deleting});
	const shareStatus = useMemo(
		() =>
			shareEvent.sharedAt && !shareEvent.result
				? statusBadges.failed
				: shareEvent.sharedAt && shareEvent.result
				? statusBadges.delivered
				: shareEvent.scheduledFor
				? statusBadges.scheduled
				: statusBadges.draft,
		[shareEvent]
	);

	return (
		<Card className={[className, styles.container]} onClick={edit}>
			<InputRow position="between">
				<Badge {...getShareStatusBadge(shareEvent)} />
				<DropdownButton
					icon="ellipsis"
					invert
					color="black"
					border={false}
					options={[
						{
							label: shareStatus !== statusBadges.delivered ? "View or edit" : "View",
							onClick: edit,
						},
						{
							label: "View analytics",
							onClick: () => navigate(user.role === "admin" ? "/analytics/company" : "/analytics/personal"),
						},

						...(shareEvent.share.companyId ? [{label: "Duplicate", onClick: () => duplicateShare()}] : []),
						{label: "Delete", onClick: deleteModal.open, disabled: shareStatus === statusBadges.delivered},
						{
							label: "Retry",
							hidden: shareStatus !== statusBadges.failed,
							onClick: () =>
								updateShareEvent({
									variables: {id: shareEvent.id, scheduledFor: dayjs().add(1, "minute")},
									onCompleted: () => toast({text: "Share event scheduled", color: "green"}),
								}),
							disabled: updating,
						},
					]}
				/>
			</InputRow>
			<Span size={1} color="blue" trim={1}>
				{title}
			</Span>
			<Span trim={2}>{opengraph?.description}</Span>
			<div className={styles.articleContainer}>
				{opengraph?.video ? (
					<video className={styles.video} controls muted>
						<source src={opengraph?.video} />
						<track kind="captions" srcLang="en" label="english_captions" />
					</video>
				) : opengraph?.image ? (
					<img src={opengraph?.image} alt={opengraph?.description} />
				) : (
					<img src="/default-image.png" alt="" />
				)}
			</div>

			{company && (
				<Span color="grey" trim={1}>
					{company}
				</Span>
			)}
			{date && (
				<div className={styles.shareEvent}>
					<Social size="x-small" name={shareEvent.network.replace("_page", "") as Service} active />
					<Span4>
						{shareEvent.sharedAt ? "Delivered " : "Scheduled "}
						{date.formatAs("numDateAndTime")}
					</Span4>
				</div>
			)}
		</Card>
	);
};
