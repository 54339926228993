import {FC, useCallback, useMemo, useRef, useState} from "react";
import classnames from "classnames";
import {Dayjs} from "dayjs";

import {
	Button,
	CalendarButton,
	EmojiPickerInput,
	FileUpload,
	InputRow,
	Separator,
	Text,
	SmallButton,
	useValidate,
} from "../../components/input";
import {OpenGraph, Service, UPLOAD_IMAGE, useMyUser} from "../../data";
import {PostServiceType} from "../../data/collection";
import {useMutationToast, useToast} from "../../toast";
import {Span, Span3} from "../../components/text";
import {Arrow, Icon} from "../../components/images";
import {OpengraphMedia} from "../../components/opengraph";
import {EmptyComponent} from "../../types";
import {useMediaLibraryModal} from "../../modals/media/media-library";
import {Media} from "../../data/media";
import {Loading} from "../../components/loading";
import {MentionsText} from "../../components/input/mentions-text";
import {AITextPicker} from "../../components/input/ai-text-picker";
import {HoverTooltip} from "../../components/tooltip";
import {networkMaxCharacters} from "./index";
import {renderMentions} from "../../utils/text";

import styles from "./post.module.scss";

interface BaseOGEditorProps extends EmptyComponent {
	opengraph: OpenGraph;
	url: string | null | undefined;
	updateOpengraph: (changes: Partial<OpenGraph>) => void;
	isURL: boolean;
	startOpen?: boolean;
	placeholder?: string;
	peakTime?: boolean;
	isLoading?: boolean;
	onRemove?: () => void;
	displayRemoveIcon?: boolean;
	showEditButton?: boolean;
}

interface BulkEditorProps extends BaseOGEditorProps {
	schedule: () => void;
	network?: never;
	setScheduledFor?: never;
	scheduledFor?: never;
	scheduling?: never;
	disabled?: boolean;
	hasErrors?: boolean;
}

interface ScheduleEditorProps extends BaseOGEditorProps {
	schedule?: never;
	network: Service;
	setScheduledFor: (value: Dayjs) => void;
	scheduledFor?: Dayjs;
	scheduling?: boolean;
	disabled?: boolean;
	hasErrors?: boolean;
}

export const OGEditor: FC<BulkEditorProps | ScheduleEditorProps> = ({
	isURL,
	isLoading,
	url,
	displayRemoveIcon,
	onRemove,
	network,
	opengraph,
	schedule,
	scheduling,
	scheduledFor,
	setScheduledFor,
	startOpen,
	updateOpengraph,
	placeholder,
	disabled,
	hasErrors,
	peakTime,
	showEditButton,
}) => {
	const [open, setOpen] = useState(startOpen);
	const [file, setFile] = useState<File>();
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	const me = useMyUser();
	const toast = useToast();

	const [uploadImage, {loading}] = useMutationToast(UPLOAD_IMAGE);
	const handleMediaUpdate = useCallback(
		(selectedMedia: Media) =>
			updateOpengraph({
				image: selectedMedia.type === "image" ? selectedMedia.url : undefined,
				video: selectedMedia.type === "video" ? selectedMedia.url : undefined,
			}),
		[updateOpengraph]
	);

	const hasUrl = useMemo(() => isURL || url, [isURL, url]);
	const tabs = useMemo<("image" | "video")[]>(() => (hasUrl ? ["image"] : ["image", "video"]), [hasUrl]);
	const mediaLibraryModal = useMediaLibraryModal({onConfirm: handleMediaUpdate, tabs});

	const onFileChange = useCallback(
		async file => {
			if (!file) return;

			const [type] = file.type.split("/");

			if (type === "image") {
				await uploadImage({variables: {file}})
					.then(({data}) => {
						const url = data?.uploadImage;
						updateOpengraph({image: url});
					})
					.catch(error => toast({color: "red", text: error, icon: "warning"}))
					.finally(() => {
						setFile(undefined);
					});
			}
		},
		[toast, updateOpengraph, uploadImage]
	);

	const handleEmojiChange = (emoji: string) => {
		const pos = textareaRef?.current?.selectionStart ?? 0;
		const comment = opengraph.comment ?? "";

		updateOpengraph({comment: `${comment.slice(0, pos)}${emoji}${comment.slice(pos)}`});
		const focusInput = () => {
			textareaRef?.current?.focus();
			textareaRef?.current?.setSelectionRange(pos + emoji.length, pos + emoji.length);
		};

		setTimeout(focusInput, 0);
	};

	const handleCommentChange = useCallback(
		comment => {
			updateOpengraph({comment});
		},
		[updateOpengraph]
	);
	const handleTitleChange = useCallback(title => updateOpengraph({title}), [updateOpengraph]);
	const handleDescriptionChange = useCallback(description => updateOpengraph({description}), [
		updateOpengraph,
	]);
	const hasOgMetadata = useMemo(
		() => isURL || (opengraph.video && (network === undefined || network === "linkedin")),
		[isURL, opengraph.video, network]
	);
	const {error} = useValidate(`${network}-comment`, opengraph.comment, {
		immediate: true,
		check: () =>
			(opengraph?.comment?.length ?? 0) > networkMaxCharacters[network || "general"]
				? "Caption is too long"
				: "",
	});

	return (
		<div className={classnames(styles.postItem, "space", (hasErrors || error) && styles.postError)}>
			<div className={classnames(styles.postHeader, "space")}>
				{network ? (
					<Icon className={classnames(open ? styles.tileElementOpen : null)} icon={network} />
				) : (
					<div />
				)}
				{open ? (
					<MentionsText
						ref={textareaRef}
						type="textarea"
						placeholder="Write a comment or paste a link to share"
						onChange={handleCommentChange}
						value={opengraph.comment}
						bare
						service={PostServiceType[network || "general"]}
						disabled={disabled || isLoading}
						characterCount
						maxLength={networkMaxCharacters[network || "general"]}
						error={error}
					/>
				) : (
					<div className={styles.previewContainer}>
						<OpengraphMedia openGraph={opengraph} width={80} height={40} />
						<Span3 trim={1}>
							{isURL
								? opengraph.title
								: renderMentions(opengraph.comment, (_id: string, display: string) => display) || placeholder}
						</Span3>
						{showEditButton && (
							<div className={styles.buttonsContainer}>
								{scheduledFor && (
									<div className={styles.scheduledForContainer}>
										<Span bold>Scheduled For</Span>
										<CalendarButton
											value={scheduledFor}
											onChange={setScheduledFor}
											color="black"
											invert
											border={false}
											icon="schedule"
											loading={scheduling}
											disabled={disabled}
										/>
									</div>
								)}
								<div className={styles.editButton}>
									<SmallButton
										invert
										border={false}
										value="Edit Post"
										arrow="down"
										onClick={() => setOpen(c => !c)}
									/>
								</div>
							</div>
						)}
						{scheduledFor && !showEditButton && (
							<CalendarButton
								value={scheduledFor}
								onChange={setScheduledFor}
								color="black"
								icon="schedule"
								loading={scheduling}
								disabled={disabled}
								border={false}
								invert
							/>
						)}
					</div>
				)}
				{(!showEditButton || open) && (
					<Arrow
						className={classnames(open ? styles.tileElementOpen : null)}
						direction={open ? "up" : "down"}
						onClick={() => setOpen(c => !c)}
					/>
				)}
			</div>
			{open && (
				<div className="space">
					{(isLoading || isURL || opengraph.image || opengraph.video) && (
						<div className={classnames(styles.ogEdit, "space", isLoading ? styles.loadingOgMedia : "")}>
							{isLoading && <Loading position="center" />}

							<OpengraphMedia
								openGraph={opengraph}
								showControls
								className={isURL && styles.ogMediaCut}
								width={450}
								height={"100%"}
							/>
							{displayRemoveIcon && <Icon icon="close" className={styles.closeIcon} onClick={onRemove} />}

							{hasOgMetadata && (
								<div className={styles.ogMetadataContainer}>
									{url && <div className={styles.ogMetadataUrl}>{url}</div>}
									<div className={styles.title}>
										<Text
											size={4}
											disabled={disabled}
											bold
											bare
											placeholder="Add a title."
											value={opengraph.title}
											onChange={handleTitleChange}
											characterCount={network === "linkedin"}
											maxLength={network ? (network === "linkedin" ? 70 : 100) : undefined}
										/>
										{me.org.options.ai && !disabled && (
											<AITextPicker
												value={opengraph.title || ""}
												label="Title"
												onChange={handleTitleChange}
												disabled={disabled}
												maxLength={100}
												className={styles.aiTextPicker}
											/>
										)}
									</div>
									{["general", "facebook"].includes(network ?? "general") && (
										<div className={styles.description}>
											<Text
												bare
												disabled={disabled}
												value={opengraph.description}
												type="textarea"
												minRows={3}
												placeholder="Add a description."
												characterCount
												onChange={handleDescriptionChange}
											/>
											{me.org.options.ai && !disabled && (
												<AITextPicker
													value={opengraph.description || ""}
													label="Description"
													onChange={handleDescriptionChange}
													disabled={disabled}
													maxLength={300}
													className={styles.aiTextPicker}
												/>
											)}
										</div>
									)}
								</div>
							)}
						</div>
					)}
					<InputRow className={styles.footerToolbar}>
						<FileUpload
							iconSize={20}
							onChange={onFileChange}
							type={["image"]}
							value={file}
							loading={loading}
							onlyIcon
							disabled={disabled}
						/>
						<div className="space">
							<HoverTooltip text="Media Library" positions={["top"]}>
								<Button
									disabled={disabled}
									icon="photo-library"
									border={false}
									invert
									onClick={mediaLibraryModal.open}
									color="black"
								/>
							</HoverTooltip>
						</div>
						<Separator />
						<EmojiPickerInput value="" onChange={handleEmojiChange} disabled={disabled} />

						{me.org.options.ai && (
							<AITextPicker
								value={
									renderMentions(opengraph.comment, (_id: string, display: string) => display) ||
									opengraph.title ||
									opengraph.description ||
									""
								}
								label="Comment"
								onChange={handleCommentChange}
								disabled={disabled}
							/>
						)}
						<Separator />
						{scheduledFor ? (
							<CalendarButton
								disabled={disabled}
								size="small"
								value={scheduledFor}
								onChange={setScheduledFor}
								color="black"
								icon="schedule"
								loading={scheduling}
								border={false}
								invert
							/>
						) : (
							schedule &&
							peakTime && (
								<Button
									disabled={disabled}
									invert
									border={false}
									icon="schedule"
									onClick={schedule}
									color="black"
									value="Schedule using Peaktime™"
								/>
							)
						)}
					</InputRow>
				</div>
			)}
		</div>
	);
};
