import {ReactElement, useEffect, useRef} from "react";

import {Card} from "../../components/card";
import {CalendarProps} from ".";
import {Span4} from "../../components/text";
import {CalendarItems} from "./calendar-items";
import {scrollTo9Am} from "./calendar-week";

import styles from "./schedule.module.scss";

export const CalendarDay = ({selectedDay, data}: Omit<CalendarProps, "setDayView">): ReactElement => {
	const hours: number[] = Array.from({length: 24}, (_, i) => i);
	const containerRef = useRef<HTMLDivElement>(null);
	const filteredData = data.filter(item => item.date?.date() === selectedDay.date());

	useEffect(() => {
		scrollTo9Am(containerRef);
	}, [containerRef]);

	return (
		<Card className={styles.dailyCalendar}>
			<div className={styles.header}>
				<h1>{selectedDay.format("DD")}</h1>
				<Span4 color="grey">{selectedDay.format("ddd")}</Span4>
			</div>

			<div ref={containerRef}>
				{hours.map(hour => (
					<div key={hour} className={styles.timeRange}>
						<Span4 color="grey">{selectedDay.hour(hour).format("h A")}</Span4>
						<div className={styles.line}>
							<CalendarItems data={filteredData} day={selectedDay} hour={hour} mode="day" />
						</div>
					</div>
				))}
			</div>
		</Card>
	);
};
