import {useState, useEffect, ReactElement, useRef} from "react";
import {Popover, PopoverPosition} from "react-tiny-popover";

import {Span} from "../text";
import {Component} from "../../types";
import {ArrowContainer} from "./ArrowContainer";

import styles from "./tooltip.module.scss";

interface TooltipProps extends Component {
	text: string;
	positions?: PopoverPosition[];
}

export const HoverTooltip = ({text, children, positions}: TooltipProps): ReactElement => {
	const [showTooltip, setShowTooltip] = useState(false);
	const defaultPositions: PopoverPosition[] = ["top", "right", "bottom", "left"];
	const timeoutRef = useRef(0);
	const showTip = () => {
		timeoutRef.current = window.setTimeout(() => {
			setShowTooltip(true);
		}, 100);
	};

	const hideTip = () => {
		clearTimeout(timeoutRef.current);
		setShowTooltip(false);
	};

	useEffect(() => () => clearTimeout(timeoutRef.current), []);

	return (
		<Popover
			isOpen={showTooltip}
			positions={positions || defaultPositions}
			padding={18}
			reposition={true}
			containerClassName={styles.container}
			onClickOutside={() => setShowTooltip(false)}
			content={({position, childRect, popoverRect}) => (
				<ArrowContainer
					position={position}
					childRect={childRect}
					popoverRect={popoverRect}
					arrowColor="#2E394A"
					arrowSize={0}
				>
					<Span bold className={styles.text}>
						{text}
					</Span>
				</ArrowContainer>
			)}
		>
			<div
				className={styles.child}
				onPointerEnter={showTip}
				onPointerLeave={hideTip}
				onBlur={hideTip}
				onClick={hideTip}
				aria-hidden="true"
			>
				{children}
			</div>
		</Popover>
	);
};
