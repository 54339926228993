import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import {useNavigate} from "react-router-dom";

import {Card} from "../../../components/card";
import {AnalyticsBadge} from "../../../components/analytics-badge";
import {UserAvatar} from "../../../components/user-avatar";
import {P, Span, Span1, Span4} from "../../../components/text";
import {Icon} from "../../../components/images";
import {nFormatter} from "../../../format";
import {Button, InputRow, RadioGroup, Select, Separator, Text} from "../../../components/input";
import {defaultLoadingProps as loadingProps, usePaginatedQuery} from "../../../paginated-query";
import {usePageScroll} from "../../../layout";
import {useGroupList} from "../../../data";
import {BaseModalArgs} from "../../../modals";
import {CloseFunc, Modal, useModal} from "../../../modals/new";
import {DateTimePicker} from "../../../components/input/date-time";
import {useMutationToast, useToast} from "../../../toast";
import {
	GET_USERS_LEADER_BOARD,
	getUserRank,
	SEND_EMAIL_CSV,
	useMyUser,
	UsersLeaderBoard,
} from "../../../data/user";
import {useDebounce} from "../../../debounce";
import {REGISTER_PAGE_EVENT} from "../../../data/badges";

import styles from "./leaderboard.module.scss";

type DateRangeType = "Max" | "0M" | "1M" | "2M" | "3M" | "4M" | "5M" | "6M";

const dateOptions = [
	{value: "Max", label: "All Time"},
	{value: "0M", label: dayjs().startOf("month").format("MMM YYYY")},
	{value: "1M", label: dayjs().add(-1, "month").format("MMM YYYY")},
	{value: "2M", label: dayjs().add(-2, "month").format("MMM YYYY")},
	{value: "3M", label: dayjs().add(-3, "month").format("MMM YYYY")},
	{value: "4M", label: dayjs().add(-4, "month").format("MMM YYYY")},
	{value: "5M", label: dayjs().add(-5, "month").format("MMM YYYY")},
	{value: "6M", label: dayjs().add(-6, "month").format("MMM YYYY")},
] as const;

const empty = <Span1>There are no current rankings to display for this group. Check back again later!</Span1>;
const LeaderBoardItem = ({userId, leaderBoard, badges}: UsersLeaderBoard): ReactElement => {
	const navigate = useNavigate();
	return (
		<Card className={styles.itemContainer} onClick={() => navigate(`/analytics/leaderboard/${userId}`)}>
			<div className={styles.userContainer}>
				<div className={styles.valueContainer}>
					<h3>{leaderBoard.rank}</h3>
					<span>Rank</span>
				</div>

				<div className={styles.avatar}>
					<UserAvatar size="extraLarge" userId={userId} name />
					<div className={styles.hint}>
						<Span4 color="blue" className={styles.text}>
							Level {getUserRank(leaderBoard.experience)}
						</Span4>
					</div>
				</div>

				<div className={styles.badgeContainer}>
					{badges.map(badge => (
						<AnalyticsBadge size="mini" badge={badge} key={badge.id} className={styles.badge} />
					))}
				</div>
			</div>
			<div className={styles.valuesContainer}>
				<div className={styles.valueContainer}>
					<Span1 color="grey">{nFormatter(leaderBoard.clicks, 0)}</Span1>
					Clicks
				</div>

				<div className={styles.valueContainer}>
					<Span1 color="grey">{nFormatter(leaderBoard.shares, 0)}</Span1>
					Shares
				</div>

				<div className={styles.valueContainer}>
					<Span1 color="grey">${nFormatter(leaderBoard.emv, 1)}</Span1>
					<div className={styles.infoContainer}>
						EMV
						<Icon
							icon="information"
							onClick={() =>
								window.open(
									"https://cvssupport.wpenginepowered.com/article/how-does-earned-media-value-work",
									"_blank"
								)
							}
						/>
					</div>
				</div>

				<div className={styles.valueContainer}>
					<Span1 color="pink">{nFormatter(leaderBoard.experience, 1)}</Span1>
					Points
				</div>

				<div className={styles.valueContainer}>
					<Span1 color="pink">{nFormatter(leaderBoard.smartScore, 1)}</Span1>
					SocialScore
				</div>
			</div>
		</Card>
	);
};
const renderItem = leaderBoard => <LeaderBoardItem key={leaderBoard.userId} {...leaderBoard} />;

interface PaginatedListProps {
	search: string;
	group: number;
	startDate?: Dayjs;
	endDate?: Dayjs;
}

export const PaginatedList = ({search, group, startDate, endDate}: PaginatedListProps): ReactElement => {
	const debouncedSearch = useDebounce(search, 300);
	const variables = useMemo(
		() => ({
			search: debouncedSearch,
			groupId: group > 0 ? group : undefined,
			global: group === -1,
			start: startDate,
			end: endDate,
		}),
		[debouncedSearch, group, startDate, endDate]
	);

	const {handleScroll, render} = usePaginatedQuery<UsersLeaderBoard>(GET_USERS_LEADER_BOARD, {
		loadingProps,
		variables,
		renderItem,
		empty,
	});
	usePageScroll(handleScroll);

	return render;
};

export interface ExportLeaderboardModal<T> extends BaseModalArgs<T> {
	initialStartDate: Dayjs;
	initialEndDate: Dayjs;
	group?: number;
	// onConfirm: () => void;
	close: CloseFunc<T>;
}
type EmailType = "SUMMARY" | "SHARES" | "CLICKS" | "VISITORS";

const reportTypes = [
	{value: "SUMMARY", label: "Share & Click Totals by User"},
	{value: "SHARES", label: "Shares by User"},
	{value: "CLICKS", label: "Clicks by User"},
	{value: "VISITORS", label: "LeadForensics Visitor Records"},
] as const;

const ExportLeaderboardModal = function <T>({
	initialStartDate,
	initialEndDate,
	group,
	close,
}: ExportLeaderboardModal<T>) {
	const [startDate, setStartDate] = useState(initialStartDate);
	const [endDate, setEndDate] = useState(initialEndDate);
	const [reportType, setReportType] = useState<EmailType>("SUMMARY");
	const [csvEmail, {loading}] = useMutationToast(SEND_EMAIL_CSV);
	const toast = useToast();
	const handleExport = useCallback(() => {
		csvEmail({
			variables: {
				startDate,
				endDate,
				type: reportType,
				groupIds: group ? [group] : [],
			},
			onCompleted: () => {
				close();
				toast({
					color: "blue",
					text: "Exporting your results into a csv file. It should arrive in your inbox in a few minutes.",
				});
			},
		});
	}, [csvEmail, endDate, group, reportType, startDate, close, toast]);
	return (
		<div className={styles.modal}>
			<Span>Select a date range:</Span>
			<DateTimePicker
				labelComponent={
					<div className={styles.dateContainer}>
						<P>From:</P>
						<Icon icon="calendar" />
						<Span>{startDate.formatAs("shortDateAndTime")}</Span>
					</div>
				}
				min={dayjs().add(-10, "years")}
				value={startDate}
				onChange={setStartDate}
				max={endDate}
			/>
			<DateTimePicker
				labelComponent={
					<div className={styles.dateContainer}>
						<P>To:</P>
						<Icon icon="calendar" />
						<Span>{endDate.formatAs("shortDateAndTime")}</Span>
					</div>
				}
				min={startDate}
				value={endDate}
				onChange={setEndDate}
				max={dayjs()}
			/>
			<RadioGroup
				label="Select a report type:"
				options={reportTypes}
				value={reportType}
				onChange={setReportType}
			/>
			<Separator horizontal />
			<InputRow position={"between"}>
				<Button onClick={close} value={"Cancel"} invert color="black" border={false} disabled={loading} />
				<Button onClick={handleExport} value={"Email CSV"} loading={loading} />
			</InputRow>
		</div>
	);
};

export const Leaderboard = (): ReactElement => {
	const [search, setSearch] = useState("");
	const [startDate, setStartDate] = useState<DateRangeType>("0M");
	const [group, setGroup] = useState(0);
	const {groups} = useGroupList();
	const me = useMyUser();
	const [registerPageEvent] = useMutationToast(REGISTER_PAGE_EVENT);

	useEffect(() => {
		registerPageEvent({
			variables: {
				type: "leaderboard",
				userId: me?.id,
			},
		});
	}, [registerPageEvent, me?.id]);

	const maxDate = useMemo(() => dayjs().diff(dayjs(me.org.created), "months"), [me]);
	const getDateParams = useMemo(
		() =>
			({
				Max: {},
				"0M": {
					startDate: dayjs().startOf("month"),
					endDate: dayjs().endOf("month").startOf("day"),
				},
				"1M": {
					startDate: dayjs().add(-1, "month").startOf("month"),
					endDate: dayjs().add(-1, "month").endOf("month"),
				},
				"2M": {
					startDate: dayjs().add(-2, "month").startOf("month"),
					endDate: dayjs().add(-2, "month").endOf("month"),
				},
				"3M": {
					startDate: dayjs().add(-3, "month").startOf("month"),
					endDate: dayjs().add(-3, "month").endOf("month"),
				},
				"4M": {
					startDate: dayjs().add(-4, "month").startOf("month"),
					endDate: dayjs().add(-4, "month").endOf("month"),
				},
				"5M": {
					startDate: dayjs().add(-5, "month").startOf("month"),
					endDate: dayjs().add(-5, "month").endOf("month"),
				},
				"6M": {
					startDate: dayjs().add(-6, "month").startOf("month"),
					endDate: dayjs().add(-6, "month").endOf("month"),
				},
			}[startDate]),
		[startDate]
	);
	const dateDiff = useMemo(
		() =>
			startDate === "Max"
				? {start: -maxDate, end: 0}
				: {start: -1 - Number(startDate.substring(0, 1)), end: 0 - Number(startDate.substring(0, 1))},
		[startDate, maxDate]
	);
	const initialStartDate = useMemo(() => dayjs().add(dateDiff.start, "month").endOf("month").startOf("day"), [
		dateDiff.start,
	]);
	const initialEndDate = useMemo(() => dayjs().add(dateDiff.end, "month").endOf("month").startOf("day"), [
		dateDiff.end,
	]);
	const {modal, open, close} = useModal({});

	return (
		<div className={styles.container}>
			<Modal modal={modal} title="Export & Email">
				<ExportLeaderboardModal
					close={close}
					initialStartDate={initialStartDate}
					initialEndDate={initialEndDate}
					group={group === -1 ? undefined : group}
				/>
			</Modal>
			<div className={styles.header}>
				<h3 className={styles.headerText}>Leaderboard</h3>
				<InputRow position="between" className={styles.inputRow}>
					<Text
						icon="search"
						value={group === -1 ? "" : search}
						placeholder="Search"
						onChange={setSearch}
						disabled={group === -1}
					/>
					<Select
						options={[{value: -1, label: "Global"}, {value: 0, label: "Everyone"}, ...groups]}
						value={group}
						placeholder="All Groups"
						onChange={setGroup}
					/>
					<Select
						className={styles.inputRow}
						options={dateOptions}
						value={startDate}
						placeholder="All Time"
						onChange={setStartDate}
					/>
					{me.role === "admin" && (
						<Button disabled={group === -1} onClick={open} icon="export" value="Export" />
					)}
				</InputRow>
			</div>
			<PaginatedList search={search} group={group} {...getDateParams} />
		</div>
	);
};
