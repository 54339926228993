import {Dayjs} from "dayjs";
import {useState} from "react";

import {BaseModalArgs, ModalHook, useConfirmModal} from ".";
import {DatePicker, HourPicker, MonthNavigator} from "../components/input/date-time";

import styles from "../containers/collections/schedule.module.scss";

interface ScheduleTime {
	value?: Dayjs;
	onChange: (value?: Dayjs) => void;
	allowPeakTime?: false | undefined;
}

interface SchedulePeakTime {
	value?: Dayjs | "peakTime";
	onChange: (value?: Dayjs | "peakTime") => void;
	allowPeakTime: true;
}

interface ExpirationModalProps {
	type: "Post" | "Collection";
	value: Dayjs;
	onConfirm: (value: Dayjs, close: () => void) => void;
	min?: Dayjs;
}

export type ScheduleProps = SchedulePeakTime | ScheduleTime;
export type ScheduleArgs<T> = BaseModalArgs<T> & ScheduleProps;

export function useDatePickerModal<T>({type, value, onConfirm, min}: ExpirationModalProps): ModalHook<T> {
	const [tempValue, setTempValue] = useState(value);
	const [displayMonth, setDisplayMonth] = useState(value.startOf("month"));

	return useConfirmModal(
		() => ({
			title: `Set Expiration Time for ${type}`,
			body: (
				<div className={styles.datepicker}>
					<MonthNavigator value={displayMonth} onChange={setDisplayMonth} />
					<DatePicker value={tempValue} displayMonth={displayMonth} onChange={setTempValue} min={min} />
					<HourPicker value={tempValue} onChange={setTempValue} />
				</div>
			),
			onConfirm: close => onConfirm(tempValue, close),
			onOpen: () => {
				setTempValue(value);
				setDisplayMonth(value.startOf("month"));
			},
		}),
		[tempValue, displayMonth, onConfirm, type, value, min]
	);
}
