import dayjs, {Dayjs} from "dayjs";
import {gql} from "@apollo/client";

import {loadDate, ShuffledImage} from ".";
import {OpenGraph, OpenGraphs, OPENGRAPH_FRAGMENT, OPENGRAPHS_FRAGMENT} from "./opengraph";

export interface MovePostVars {
	id: number;
	postId: number;
	categoryId?: number;
	afterPostId?: number;
}

export const PostServiceType = {
	general: "GENERAL",
	twitter: "TWITTER",
	linkedin: "LINKEDIN",
	instagram: "INSTAGRAM",
	facebook: "FACEBOOK",
} as const;
export type PostService = typeof PostServiceType[keyof typeof PostServiceType];

export type CollectionStatus = "approved" | "draft" | "scheduled" | "sent";

export interface PostShare {
	id: number;
	created: Dayjs;
	clicks: number;
	viewed: number;
	userId: number;
	mediaValueSum: number;
	successCount: number;
}
export interface Post {
	id: number;
	categoryId?: number;
	opengraphs: OpenGraphs;
	expiresAt?: Dayjs;
	url?: string | null;
	shuffledCommentsCount: number;
	shuffledImages: ShuffledImage[];
	keywords: PostKeyword[];
	shares?: PostShare[];
}

const POST_DEFAULT_EMV = 5.17;
export const getPostEmv = (post: Post): number => {
	const emv = post.keywords.reduce((acc, obj) => acc + obj.value, 0) / post.keywords.length;

	return post.url ? (emv > 0 ? emv : POST_DEFAULT_EMV) : 0;
};

export interface PostKeyword {
	id: number;
	keyword: string;
	value: number;
	global: boolean;
}

export interface AutoLike {
	linkedin: boolean;
	twitter: boolean;
}

export interface Collection {
	id: number;
	status: CollectionStatus;
	categories?: number[];
	posts: Post[];
	to: {
		groupIds: number[];
		virtualAssistantIds: number[];
		companyIds: number[];
		slackChannels: string[];
		teamsChannels: string[];
	};
	autoLike: AutoLike;
	title: string;
	expires?: Dayjs;
	scheduledFor?: Dayjs;
	trackingUrl?: string;
	message?: string;
	createdBy: number;
	owner: number;
	created: Dayjs;
	updated?: Dayjs;
	sent?: Dayjs;
	shares?: PostShare[];
	deliveryMessage?: string;
}

export interface PostsStats {
	postsCount: number;
	usersCount: number;
	sharesCount: number;
	emv: number;
	postTitle: string;
	postImage: string;
	postVideo: string;
}

export interface AnalyticsCollection {
	id: number;
	title: string;
	sent?: Dayjs;
	message?: string;
	postsStats: PostsStats;
	createdBy: number;
	to: {
		groupIds: number[];
	};
}

export interface CollectionStats {
	unopenedUserIds: number[];
	emailedUserIds: number[];
}
export interface TopPost {
	id: number;
	clicks: number;
	url: string;
	openGraph: OpenGraph;
	emv: number;
	shares: number;
}

const POST_SHARE_FRAGMENT = gql`
	fragment PostShareFields on PostShare {
		id
		created
		clicks
		viewed
		userId
		mediaValueSum
		successCount
	}
`;

const POST_KEYWORD_FRAGMENT = gql`
	fragment PostKeywordFields on PostKeyword {
		id
		keyword
		value
		global
	}
`;

export const GET_POST_KEYWORDS = gql`
	query {
		postKeywords {
			...PostKeywordFields
		}
	}
	${POST_KEYWORD_FRAGMENT}
`;

export const COLLECTION_FRAGMENT = gql`
	fragment CollectionFields on Collection {
		id
		title
		message
		status
		categories
		owner
		createdBy
		scheduledFor
		created
		updated
		sent
		autoLike {
			twitter
			linkedin
		}
		posts {
			id
			categoryId
			url
			expiresAt
			opengraphs {
				...OpenGraphsFields
			}
			shuffledCommentsCount
			shuffledImages {
				id
				image
			}
			keywords {
				...PostKeywordFields
			}
			shares {
				...PostShareFields
			}
		}
		to {
			groupIds
			slackChannels
			virtualAssistantIds
			companyIds
			teamsChannels
		}
		deliveryMessage
	}
	${OPENGRAPHS_FRAGMENT}
	${POST_KEYWORD_FRAGMENT}
	${POST_SHARE_FRAGMENT}
`;

export const ANALYTICS_COLLECTION_FRAGMENT = gql`
	fragment AnalyticsCollectionFields on Collection {
		id
		title
		sent
		message
		postsStats {
			postsCount
			usersCount
			sharesCount
			emv
			postTitle
			postImage
			postVideo
		}
		createdBy
		to {
			groupIds
		}
	}
`;

export const EXPORT_ANALYTICS_COLLECTION_FRAGMENT = gql`
	fragment ExportAnalyticsCollectionFields on PostExportStats {
		id
		category
		click_count
		emv
		success_count
		viewed
		url
		collection {
			id
			title
			message
			sent
		}
		openGraph {
			image
			title
			description
			comment
			video
		}
	}
`;

const COLLECTION_STATS_FRAGMENT = gql`
	fragment CollectionStatsFields on CollectionStats {
		unopenedUserIds
		emailedUserIds
	}
`;

export const GET_COLLECTION_STATS = gql`
	query CollectionStats($collectionId: PositiveInt!) {
		collectionStats(collectionId: $collectionId) {
			...CollectionStatsFields
		}
	}
	${COLLECTION_STATS_FRAGMENT}
`;

export const CREATE_COLLECTION = gql`
	mutation CreateCollection {
		createCollection {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const DELETE_COLLECTION = gql`
	mutation DeleteCollection($id: PositiveInt!) {
		deleteCollection(id: $id) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const DUPLICATE_COLLECTION = gql`
	mutation DuplicateCollection($id: PositiveInt!) {
		duplicateCollection(id: $id) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const GET_COLLECTION = gql`
	query Collection($id: PositiveInt!) {
		collection(id: $id) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const GET_COLLECTIONS_EXPORT = gql`
	query CollectionsExport(
		$start: DateTime!
		$end: DateTime!
		$groups: [PositiveInt!]
		$owner: PositiveInt
		$search: String
	) {
		exportCollections(start: $start, end: $end, limit: 500, groups: $groups, owner: $owner, search: $search) {
			...ExportAnalyticsCollectionFields
		}
	}
	${EXPORT_ANALYTICS_COLLECTION_FRAGMENT}
`;

export const GET_COLLECTIONS = gql`
	query Collections(
		$cursor: String
		$groups: [PositiveInt!]
		$limit: NonNegativeInt
		$owner: [PositiveInt!]
		$sort: CollectionSort
		$status: [CollectionStatus!]
		$start: DateTime
		$end: DateTime
		$search: String
		$ids: [PositiveInt!]
	) {
		collections(
			cursor: $cursor
			groups: $groups
			limit: $limit
			owner: $owner
			sort: $sort
			status: $status
			start: $start
			end: $end
			search: $search
			ids: $ids
		) {
			cursor
			items {
				...CollectionFields
			}
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const GET_ANALYTICS_COLLECTIONS = gql`
	query AnalyticsCollections(
		$cursor: String
		$groups: [PositiveInt!]
		$limit: NonNegativeInt
		$owner: [PositiveInt!]
		$sort: CollectionSort
		$status: [CollectionStatus!]
		$start: DateTime
		$end: DateTime
		$search: String
		$ids: [PositiveInt!]
	) {
		collections(
			cursor: $cursor
			groups: $groups
			limit: $limit
			owner: $owner
			sort: $sort
			status: $status
			start: $start
			end: $end
			search: $search
			ids: $ids
		) {
			cursor
			items {
				...AnalyticsCollectionFields
			}
		}
	}
	${ANALYTICS_COLLECTION_FRAGMENT}
`;

export const SAVE_COLLECTION = gql`
	mutation UpdateCollection($id: PositiveInt!, $changes: UpdateCollection!) {
		updateCollection(id: $id, changes: $changes) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const ADD_POST_FROM_RSS_FEED = gql`
	mutation addPostFromRssFeed($id: PositiveInt!, $postId: PositiveInt!) {
		addPostFromRssFeed(id: $id, postId: $postId) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const STOP_ALL_FUTURE_SHARES = gql`
	mutation StopAllFutureShares($id: PositiveInt!, $postIds: [PositiveInt!]!) {
		stopAllFutureShares(id: $id, postIds: $postIds) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const ADD_POST_FROM_SUGGESTED_FEED = gql`
	mutation addPostFromSuggestedFeed($id: PositiveInt!, $postId: PositiveInt!, $postType: String!) {
		addPostFromSuggestedFeed(id: $id, postId: $postId, postType: $postType) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const ADD_POST = gql`
	mutation AddPost($id: PositiveInt!, $openGraphData: OpenGraphInput!) {
		addPost(id: $id, openGraphData: $openGraphData) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const UPDATE_POST = gql`
	mutation UpdatePost(
		$id: PositiveInt!
		$postId: PositiveInt!
		$changes: UpdatePost!
		$deletions: PostDeletions!
	) {
		updatePost(id: $id, postId: $postId, changes: $changes, deletions: $deletions) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const DELETE_POST = gql`
	mutation DeletePost($id: PositiveInt!, $postId: PositiveInt!) {
		deletePost(id: $id, postId: $postId) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const MOVE_POST = gql`
	mutation MovePost(
		$id: PositiveInt!
		$postId: PositiveInt!
		$categoryId: PositiveInt
		$afterPostId: PositiveInt
	) {
		movePost(id: $id, postId: $postId, categoryId: $categoryId, afterPostId: $afterPostId) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const ADD_CATEGORY_TO_COLLECTION = gql`
	mutation AddCategoryToCollection($id: PositiveInt!, $categoryId: PositiveInt!) {
		addCategoryToCollection(id: $id, categoryId: $categoryId) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const REMOVE_CATEGORY_FROM_COLLECTION = gql`
	mutation RemoveCategoryFromCollection($id: PositiveInt!, $categoryId: PositiveInt!) {
		removeCategoryFromCollection(id: $id, categoryId: $categoryId) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const REORDER_CATEGORIES = gql`
	mutation ReorderCollectionCategories($id: PositiveInt!, $categoryIds: [PositiveInt!]!) {
		reorderCollectionCategories(id: $id, categoryIds: $categoryIds) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const RESEND_COLLECTION = gql`
	mutation ResendCollection($id: PositiveInt!) {
		resendCollection(id: $id) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

const TOP_POST_FRAGMENT = gql`
	fragment TopPostFields on TopPost {
		id
		clicks
		shares
		emv
		url
		openGraph {
			...OpenGraphFields
		}
	}
	${OPENGRAPH_FRAGMENT}
`;
export const GET_TOP_POSTS = gql`
	query TopPost($start: DateTime!, $end: DateTime!, $cursor: String) {
		topPosts(start: $start, end: $end, cursor: $cursor) {
			cursor
			items {
				...TopPostFields
			}
		}
	}
	${TOP_POST_FRAGMENT}
`;

export const ADD_ORG_KEYWORD = gql`
	mutation($keyword: String!) {
		addNewKeyword(keyword: $keyword) {
			...PostKeywordFields
		}
	}
	${POST_KEYWORD_FRAGMENT}
`;

export const ADD_POST_DRAFT_COLLECTION = gql`
	mutation AddPostDraftCollection($url: String!) {
		addPostDraftCollection(url: $url) {
			...CollectionFields
		}
	}
	${COLLECTION_FRAGMENT}
`;

export const loadCollection = (collection): Collection =>
	({
		...collection,
		posts: collection.posts.map(p => ({
			...p,
			expiresAt: loadDate(p.expiresAt),
			shares: p.shares?.map(s => ({...s, created: loadDate(s.created)})),
		})),
		status: collection.status.toLowerCase(),
		scheduledFor: loadDate(collection.scheduledFor),
		updated: loadDate(collection.updated),
		sent: loadDate(collection.sent),
		created: dayjs(collection.created),
	} as Collection);

export const transformAnalyticsCollection = (collection): AnalyticsCollection =>
	({
		...collection,
		sent: loadDate(collection.sent),
	} as AnalyticsCollection);
