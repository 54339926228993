import {FC, KeyboardEvent, useCallback, useMemo} from "react";
import classnames from "classnames/bind";

import {Arrow} from "../images";
import {AvatarSize} from "../../types";
import {UserAvatar} from "../user-avatar";
import {Role, useSimpleUsers} from "../../data";
import {useStateRef} from "../../state-ref";
import {Input, SingleOptionProps, MultiOptionProps, ValidationCheck, toggle, useId, useValidate} from ".";
import {DropdownCallback, useDropdown} from "./dropdown";
import {Span} from "../text";

import styles from "./input.module.scss";
const bStyles = classnames.bind(styles);

interface SingleSelect extends Omit<SingleOptionProps<number>, "options"> {
	multi?: false | null | undefined;
	name?: boolean;
	placeholder?: string;
	size?: AvatarSize;
	roles?: Role[];
}
interface MultiSelect extends Omit<MultiOptionProps<number>, "options"> {
	multi: true;
	name?: boolean;
	placeholder?: string;
	size?: AvatarSize;
	roles?: Role[];
}

export type UserSelectProps = MultiSelect | SingleSelect;

export const UserSelect: FC<UserSelectProps> = ({
	disabled,
	id: maybeId,
	multi,
	name,
	onChange,
	placeholder,
	roles,
	size,
	validate,
	value,
	...props
}) => {
	const id = useId(maybeId);
	const allUsers = useSimpleUsers({limit: null, filter: {roles: roles?.map(e => e.toUpperCase()) as Role[]}});
	const [activeIndex, setActiveIndex, aiRef] = useStateRef<number | undefined>(undefined);
	const {error, inputProps, update} = useValidate<number | number[]>(
		id,
		value,
		validate as ValidationCheck<number | number[]> | undefined
	);
	const handleUnset = useCallback(() => setActiveIndex(undefined), [setActiveIndex]);
	const users = useMemo(() => (roles?.length ? allUsers.filter(u => roles.includes(u.role)) : allUsers), [
		allUsers,
		roles,
	]);

	const [valueArray, selected] = useMemo(() => {
		const valueArray = multi ? value : [value];
		const selected = users.filter(u => valueArray.includes(u.id));
		return [valueArray, selected];
	}, [multi, users, value]);

	const handleChange = useCallback(
		(v: number, close: () => void) => {
			if (!multi) {
				close();
				onChange(v);
				update();
				return;
			}
			onChange(toggle(v, value));
			update();
		},
		[multi, onChange, update, value]
	);

	const handleKeyDown = useCallback(
		(e: KeyboardEvent<HTMLDivElement>, {close}: DropdownCallback) => {
			switch (e.key) {
				case "Enter":
				case " ":
					if (aiRef.current !== undefined) {
						handleChange(users[aiRef.current].id, close);
						if (multi) setActiveIndex(c => ((c || 1) % users.length) - 1);
					}
					break;
				case "ArrowDown":
					setActiveIndex(c => ((c ?? -1) + 1) % users.length);
					break;
				case "ArrowUp":
					setActiveIndex(c => ((c ?? users.length + 1) - 1 + users.length) % users.length);
					break;
				default:
					return;
			}
			e.stopPropagation();
			e.preventDefault();
		},
		[aiRef, handleChange, multi, setActiveIndex, users]
	);

	const popup = useCallback(
		({close}: DropdownCallback) =>
			users.map((u, i) => (
				<div
					className={bStyles("option", {active: i === activeIndex, selected: valueArray.includes(u.id)})}
					key={u.id}
					tabIndex={i === activeIndex ? 0 : -1}
					onMouseOver={() => setActiveIndex(i)}
					onClick={e => {
						e.stopPropagation();
						handleChange(u.id, close);
					}}
				>
					<UserAvatar userId={u.id} size="extraSmall" name />
				</div>
			)),
		[activeIndex, handleChange, setActiveIndex, users, valueArray]
	);

	const {isOpen, portal, reference, toggle: toggleDropdown} = useDropdown({
		popup,
		portalClassName: styles.menu,
		onClose: handleUnset,
		onKeyDown: handleKeyDown,
		onMouseOut: handleUnset,
	});

	return (
		<Input disabled={disabled} id={id} {...props} {...inputProps}>
			<div
				onClick={disabled ? undefined : toggleDropdown}
				ref={reference}
				className={bStyles("userSelect", {error, open: isOpen, disabled})}
				tabIndex={disabled ? -1 : 0}
			>
				{!selected.length ? (
					<Span color="grey">{placeholder}</Span>
				) : (
					<UserAvatar userId={valueArray[0]} size={size} name={name} />
				)}
				{!disabled && <Arrow direction={isOpen ? "up" : "down"} height={12} />}
				{portal}
			</div>
		</Input>
	);
};
