import {FC, useCallback, useState, KeyboardEvent} from "react";
import classnames from "classnames";

import {Button} from "../button";
import {AlerterWidget} from "../../alerter";
import {AIAssistant} from "./ai-assistant";
import {HoverTooltip} from "../../tooltip";

import styles from "./ai-text-picker.module.scss";

export interface AITextPickerProps {
	value: string;
	label?: string;
	onChange: (value: string) => void;
	disabled?: boolean;
	maxLength?: number;
	className?: string;
}

export const AITextPicker: FC<AITextPickerProps> = ({
	label,
	value,
	disabled,
	onChange,
	maxLength,
	className,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const handleOpen = useCallback(c => !disabled && setIsOpen(c), [disabled]);
	const handleClick = useCallback(() => !disabled && setIsOpen(prevState => !prevState), [disabled]);

	const handleKeyDown = useCallback(
		(e: KeyboardEvent<HTMLDivElement>) => {
			if (disabled) return;

			switch (e.key) {
				case "Enter":
				case " ":
					setIsOpen(true);
					break;
				case "Escape":
					setIsOpen(false);
					break;
				default:
					return;
			}
		},
		[disabled]
	);

	return (
		<div tabIndex={disabled ? -1 : 0} onKeyDown={handleKeyDown} className={classnames("space", className)}>
			<AlerterWidget
				onOpen={handleOpen}
				isOpen={isOpen}
				placement="top-start"
				labelComponent={
					<HoverTooltip text={"AI Assistant"} positions={["top"]}>
						<Button
							className={styles.pickerButton}
							invert={true}
							icon="robot"
							iconSize="medium"
							color="black"
							onClick={handleClick}
							border={false}
							disabled={disabled}
							active={isOpen}
						/>
					</HoverTooltip>
				}
			>
				<AIAssistant
					onChange={onChange}
					onClose={handleClick}
					promptLabel={label}
					promptValue={value}
					maxLength={maxLength}
				/>
			</AlerterWidget>
		</div>
	);
};
